import { getLocaleNumberFormat, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Compra } from '@models/compra.model';
import { Compramaterial } from '@models/compramaterial.model';
import { Deuda } from '@models/deuda.model';
import { Material } from '@models/material.model';
import { Producto } from '@models/producto.model';
import { Tarea } from '@models/tarea.model';
import { Venta } from '@models/venta.model';
import { Ventaproducto } from '@models/ventaproducto.model';
import { CompraService } from '@services/compra.service';
import { CompramaterialService } from '@services/compramaterial.service';
import { DeudaService } from '@services/deuda.service';
import { MaterialService } from '@services/material.service';
import { ProductoService } from '@services/producto.service';
import { TareaService } from '@services/tarea.service';
import { VentaService } from '@services/venta.service';
import { VentaproductoService } from '@services/ventaproducto.service';
import { Chart, ChartConfiguration, ChartOptions, ChartType } from "chart.js";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent {

    title = 'ng2-charts-demo';

    public ventasEstadisticas;
    public comprasEstadisticas;

    restaAcumulada;
    restaMes;

    dataVentas = [];
    dataCompras = [];
    labelsProductos = [];
    dataProductos = [];
    labelsMateriales = [];
    dataMateriales = [];
    labelsProductosStock = [];
    dataProductosStock = [];
    deudaPago;
    tareaData;

    dataInsumos = [];
    dataInsumosStock = [];
    dataInsumosLabels = [];

    dataVentaDeudaSinFiltrar = [];
    dataVentaDeudaFiltrada = [0,0,0,0,0,0,0,0,0,0,0,0];
    dataVentaDeudaFecha = [];

    Tareas: Tarea[]
    Productos: Producto[]

    chartLine;
    chartBar;
    chartBarStock;
    chartProductosVendidos;
    chartMaterialesComprados;
    chartDeudas;
    chartTareas;
    chartBarStockInsumos;
    chartVentaDeuda;

    totalVentasMostrarAcumulado:string;
    totalVentasMostrarMes:string;
    totalComprasMostrarAcumulado:string;
    totalComprasMostrarMes:string;
    utilidadMostrarAcumulado:string;
    utilidadMostrarMes:string;

    public utilidadPorcentualAcumulado=0;
    public utilidadPorcentualMes=0;


    public doughnutChartLabels: string[] = [ 'Download Sales', 'In-Store Sales', 'Mail-Order Sales' ];
    public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
        { data: [ 350, 450, 100 ], label: 'Series A' },
        { data: [ 50, 150, 120 ], label: 'Series B' },
        { data: [ 250, 130, 70 ], label: 'Series C' }
      ];

    public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
      responsive: false
    };

    
    @ViewChild('myChartBar')
    myChartBar: ElementRef;

    constructor(private ventaService:VentaService,
                private compraService:CompraService,
                private productoService: ProductoService,
                private materialesService:MaterialService,
                private ventaProductoService:VentaproductoService,
                private compraMaterialesService:CompramaterialService,
                private deudasService:DeudaService,
                private tareaService:TareaService) {
      
    }
  
    ngOnInit(){
      this.recalcular();

    }
   recalcular() {

      this.productoService.getProductosEstadistica(null).subscribe((res) => {
          this.Productos = res;
      });

      this.tareaService.getTareasEstadisticaTop(null).subscribe((res) => {
        this.Tareas = res;
      });

      this.ventaService.getVentasEstadistica(null).subscribe((dataVenta: Venta[]) => {
        this.compraService.getComprasEstadistica(null).subscribe((data: Compra[]) => {
          this.ventaProductoService.getVentasProductosEstadistica(null,null).subscribe((dataVentaProducto: Ventaproducto[]) => {
            this.compraMaterialesService.getComprasMaterialesEstadistica(null,null).subscribe((dataCompraMaterial: Compramaterial[]) => {
              this.productoService.getProductos(null).subscribe((dataProductos: Producto[]) => {
                this.deudasService.getDeudaEstadistica(null).subscribe((dataDeudas:Deuda[]) => {
                  this.tareaService.getTareasEstadistica(null).subscribe((dataTareas:Tarea[]) => {
                    this.materialesService.getMateriales(null,1).subscribe((dataInsumos: Material[]) => {
                      this.ventaService.getVentas(null).subscribe((dataVentaDeudaSinFiltrar: Venta[]) => {

                        this.ventasEstadisticas = dataVenta[0];
                        this.totalVentasMostrarAcumulado = parseInt(this.ventasEstadisticas.ACUMULADO).toLocaleString('es-CL')
                        this.totalVentasMostrarMes = parseInt(this.ventasEstadisticas.MES_ACTUAL).toLocaleString('es-CL');

                        this.dataVentas[0] = this.ventasEstadisticas.ENERO;
                        this.dataVentas[1] = this.ventasEstadisticas.FEBRERO;
                        this.dataVentas[2] = this.ventasEstadisticas.MARZO;
                        this.dataVentas[3] = this.ventasEstadisticas.ABRIL;
                        this.dataVentas[4] = this.ventasEstadisticas.MAYO;
                        this.dataVentas[5] = this.ventasEstadisticas.JUNIO;
                        this.dataVentas[6] = this.ventasEstadisticas.JULIO;
                        this.dataVentas[7] = this.ventasEstadisticas.AGOSTO;
                        this.dataVentas[8] = this.ventasEstadisticas.SEPTIEMBRE;
                        this.dataVentas[9] = this.ventasEstadisticas.OCTUBRE;
                        this.dataVentas[10] = this.ventasEstadisticas.NOVIEMBRE;
                        this.dataVentas[11] = this.ventasEstadisticas.DICIEMBRE;


                        this.comprasEstadisticas = data[0];
                        this.totalComprasMostrarAcumulado = parseInt(this.comprasEstadisticas.ACUMULADO).toLocaleString('es-CL');
                        this.totalComprasMostrarMes = parseInt(this.comprasEstadisticas.MES_ACTUAL).toLocaleString('es-CL');

                        this.restaAcumulada = this.ventasEstadisticas.ACUMULADO - this.comprasEstadisticas.ACUMULADO;
                        this.utilidadMostrarAcumulado = parseInt( this.restaAcumulada).toLocaleString('es-CL');

                        this.restaMes = this.ventasEstadisticas.MES_ACTUAL - this.comprasEstadisticas.MES_ACTUAL;
                        this.utilidadMostrarMes = parseInt( this.restaMes).toLocaleString('es-CL');

                        this.utilidadPorcentualAcumulado = Math.round(this.restaAcumulada /  this.ventasEstadisticas.ACUMULADO * 100)

                        this.utilidadPorcentualMes = Math.round(this.restaMes /  this.ventasEstadisticas.MES_ACTUAL * 100)

                        this.dataCompras[0] = this.comprasEstadisticas.ENERO;
                        this.dataCompras[1] = this.comprasEstadisticas.FEBRERO;
                        this.dataCompras[2] = this.comprasEstadisticas.MARZO;
                        this.dataCompras[3] = this.comprasEstadisticas.ABRIL;
                        this.dataCompras[4] = this.comprasEstadisticas.MAYO;
                        this.dataCompras[5] = this.comprasEstadisticas.JUNIO;
                        this.dataCompras[6] = this.comprasEstadisticas.JULIO;
                        this.dataCompras[7] = this.comprasEstadisticas.AGOSTO;
                        this.dataCompras[8] = this.comprasEstadisticas.SEPTIEMBRE;
                        this.dataCompras[9] = this.comprasEstadisticas.OCTUBRE;
                        this.dataCompras[10] = this.comprasEstadisticas.NOVIEMBRE;
                        this.dataCompras[11] = this.comprasEstadisticas.DICIEMBRE;
                        
                        for(let i=0; i<dataVentaProducto.length; i++){
                          this.labelsProductos.push(dataVentaProducto[i].nombre);
                          this.dataProductos.push(dataVentaProducto[i].cantidad);
                        }

                        for(let i=0; i<dataCompraMaterial.length; i++){
                          this.labelsMateriales.push(dataCompraMaterial[i].nombre);
                          this.dataMateriales.push(dataCompraMaterial[i].cantidad);
                        }

                        for(let i=0; i<dataProductos.length; i++){
                          this.labelsProductosStock.push(dataProductos[i].nombre);
                          this.dataProductosStock.push(dataProductos[i].cantidad);
                        }

                        for(let i=0; i<dataInsumos.length; i++){
                          this.dataInsumosLabels.push(dataInsumos[i].nombre);
                          this.dataInsumosStock.push(dataInsumos[i].cantidad);
                        }

                        for(let i=0; i<dataVentaDeudaSinFiltrar.length; i++){
                          if(dataVentaDeudaSinFiltrar[i].medio_pago == "Deuda"){
                            //this.dataVentaDeudaFiltrada.push(dataVentaDeudaSinFiltrar[i].valor);
                            let mes = dataVentaDeudaSinFiltrar[i].fecha_edicion.substring(5,7);
                            console.log(mes);

                            var valor =  dataVentaDeudaSinFiltrar[i].deuda.toString();

                            switch(mes){
                              case '01':
                                this.dataVentaDeudaFiltrada[0] = this.dataVentaDeudaFiltrada[0] + parseInt(valor);
                                break
                              case '02':
                                this.dataVentaDeudaFiltrada[1] = this.dataVentaDeudaFiltrada[1] + parseInt(valor);
                              break
                              case '03':
                                this.dataVentaDeudaFiltrada[2] = this.dataVentaDeudaFiltrada[2] + parseInt(valor);
                              break
                              case '04':
                                this.dataVentaDeudaFiltrada[3] = this.dataVentaDeudaFiltrada[3] + parseInt(valor);
                              break
                              case '05':
                                this.dataVentaDeudaFiltrada[4] = this.dataVentaDeudaFiltrada[4] + parseInt(valor)
                              break
                              case '06':
                                this.dataVentaDeudaFiltrada[5] = this.dataVentaDeudaFiltrada[5] + parseInt(valor)
                              break
                              case '07':
                                this.dataVentaDeudaFiltrada[6] = this.dataVentaDeudaFiltrada[6] + parseInt(valor)
                              break
                              case '08':
                                this.dataVentaDeudaFiltrada[7] = this.dataVentaDeudaFiltrada[7] + parseInt(valor)
                              break
                              case '09':
                                this.dataVentaDeudaFiltrada[8] = this.dataVentaDeudaFiltrada[8] + parseInt(valor)
                              break
                              case '10':
                                this.dataVentaDeudaFiltrada[9] = this.dataVentaDeudaFiltrada[9] + parseInt(valor)
                              break
                              case '11':
                                this.dataVentaDeudaFiltrada[10] = this.dataVentaDeudaFiltrada[10] + parseInt(valor)
                              break
                              case '12':
                                this.dataVentaDeudaFiltrada[11] = this.dataVentaDeudaFiltrada[11] + parseInt(valor)
                              break

                            }
                          }
                        }

                        const canvasBar = this.myChartBar.nativeElement.getContext("2d");
                        this.chartBar = new Chart(canvasBar, {
                          type: 'bar',
                          data: {
                            labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
                            datasets: [
                              {
                                label: 'Ventas',
                                data: this.dataVentas,
                                borderWidth: 1,
                                borderColor: 'black',
                                backgroundColor: 'rgba(0,255,0,0.3)'
                              },
                              {
                                label: 'Gastos',
                                data: this.dataCompras,
                                borderWidth: 1,
                                borderColor: 'black',
                                backgroundColor: 'rgba(255,0,0,0.3)'
                              },
                            ],
                          },
                          options: {
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          },
                        });


                        this.chartVentaDeuda = new Chart('canvasVentaDeuda', {
                          type: 'bar',
                          data: {
                            labels: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
                            datasets: [
                              {
                                label: 'Por Cobrar',
                                data: this.dataVentaDeudaFiltrada,
                                borderWidth: 1,
                                borderColor: 'black',
                                backgroundColor: 'rgba(0,255,0,0.3)'
                              },
                            ],
                          },
                          options: {
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          },
                        });

                      this.chartBarStock = new Chart('canvasBarStock', {
                          type: 'bar',
                          data: {
                            labels: this.labelsProductosStock,
                            datasets: [
                              {
                                label: 'Productos',
                                data: this.dataProductosStock,
                                borderWidth: 1,
                                borderColor: 'black',
                                backgroundColor: 'rgba(0,255,0,0.3)'
                              },
                            ],
                          },
                          options: {
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          },
                        });

                        this.chartBarStockInsumos = new Chart('canvasBarStockInsumos', {
                          type: 'bar',
                          data: {
                            labels: this.dataInsumosLabels,
                            datasets: [
                              {
                                label: 'Insumos',
                                data: this.dataInsumosStock,
                                borderWidth: 1,
                                borderColor: 'black',
                                backgroundColor: 'rgba(0,255,0,0.3)'
                              },
                            ],
                          },
                          options: {
                            scales: {
                              y: {
                                beginAtZero: true,
                              },
                            },
                          },
                        });

                        this.chartProductosVendidos = new Chart('canvasProductosVendidos', {
                          type: 'bar',
                          data: {
                            labels: this.labelsProductos,
                            datasets: [
                              {
                                label:"Productos Vendidos",
                                borderWidth: 1,
                                borderColor: 'black',
                                data: this.dataProductos,
                              },
                            ],
                          },
                        });

                        this.chartMaterialesComprados = new Chart('canvasMaterialesComprados', {
                          type: 'bar',
                          data: {
                            labels: this.labelsMateriales,
                            datasets: [
                              {
                                label:"Insumos Comprados",
                                borderWidth: 1,
                                borderColor: 'black',
                                data: this.dataMateriales,
                              },
                            ],
                          },
                        });

                        this.deudaPago = dataDeudas[0];
                        this.chartDeudas = new Chart('canvasDeudas', {
                          type: 'bar',
                          data: {
                            labels: [''],
                            datasets: [
                              {
                                label:"Deudas",
                                data: [this.deudaPago.deudas],
                              },
                              {
                                label:"Pagos",
                                data: [this.deudaPago.pagos],
                              },
                            ],
                          },
                        });

                        this.tareaData = dataTareas[0];
                        this.chartDeudas = new Chart('canvasTareas', {
                          type: 'bar',
                          data: {
                            labels: [''],
                            datasets: [
                              {
                                label: 'Pendientes',
                                data: [this.tareaData.pendientes],
                              },
                              {
                                label: 'Completadas',
                                data: [this.tareaData.completadas],
                              },
                            ],
                          },
                        });

                      })
                    })
                  })
                })
              })
            });
          });
        });
      });
    }
  }
