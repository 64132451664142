import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  constructor(private angulafirestore: AngularFirestore,
              public http: HttpClient) { }

  getUsuarios(id){
    let data = {
      id: id
    };
    return this.http.post<Usuario[]>('https://inclunova.cl/sgiCoquena/usuariosGet.php',data);
  }

  getUsuariosLogin(email, password){
    let data = {
      email: email,
      password:password
    };
    return this.http.post<Usuario[]>('https://inclunova.cl/sgiCoquena/usuariosGetLogin.php',data);
  }

  createUsuario(usuario:Usuario){
    let link= 'https://inclunova.cl/sgiCoquena/usuariosInsert.php';
    let data = {
      nombre:usuario.nombre,
      apellidop: usuario.apellidop,
      apellidom:  usuario.apellidom,
      rut: usuario.rut,
      dv:usuario.dv,
      email:usuario.email,
      passwords:usuario.password
    };
    return this.http.post(link, data);
  }

  updateUsuario(usuario:Usuario,id){
    let link= 'https://inclunova.cl/sgiCoquena/usuariosUpdate.php';
    let data = {
      id:id,
      nombre:usuario.nombre,
      apellidop: usuario.apellidop,
      apellidom:  usuario.apellidom,
      email:usuario.email,
      passwords:usuario.password
    };

    console.log(data);
    return this.http.post(link, data);
  }

  deletelUsuarioLogic(id){
      let link= 'https://inclunova.cl/sgiCoquena/usuariosDelete.php';
      let data = {
          id:id
      };
      return this.http.post(link, data)
  }


  // //obtener
  // getUsuario(){
  //   return this.angulafirestore
  //   .collection("usuarios", ref => ref.where('borrado', '!=', 1))
  //   .snapshotChanges()
  // }
  // getUsuarioid(id){
  //   return this.angulafirestore
  //   .collection("usuarios")
  //   .doc(id)
  //   .valueChanges()
  // }

  // createUsuario(usuario:Usuario){
  //   usuario.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   usuario.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   return new Promise<any> (( resolve, reject ) =>{
  //     this.angulafirestore
  //     .collection("usuarios")
  //     .add(usuario)
  //     .then( (Response) => {
  //       console.log(Response)
  //     },
  //     (error) => {
  //       reject(error)
  //     })
  //   }) 
  // }

  // updateUsuario(usuario: Usuario, id){
  //   usuario.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   return this.angulafirestore
  //   .collection("usuarios")
  //   .doc(id)
  //   .update({
  //     nombre: usuario.nombre,
  //     apellidop: usuario.apellidop,
  //     apellidom: usuario.apellidom,
  //     rut: usuario.rut,
  //     dv: usuario.dv
  //   })
  // }
  // deleteUsuarioLogic(usuario: Usuario, id){
  //   return this.angulafirestore
  //   .collection("usuarios")
  //   .doc(id)
  //   .update({
  //     borrado: usuario.borrado = 1
  //   })
  // }
  // deleteUsuario(usuario){
  //   return this.angulafirestore
  //   .collection("usuarios")
  //   .doc(usuario.id)
  //   .delete()
  // }
}
