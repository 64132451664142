import { Component, OnInit } from '@angular/core';
import { ProductoService } from '@services/producto.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { Producto } from '@models/producto.model';
@Component({
  selector: 'app-editarproductos',
  templateUrl: './editarproductos.component.html',
  styleUrls: ['./editarproductos.component.scss']
})
export class EditarproductosComponent {
  public EditProductosForm: FormGroup;
  productoRef: any

  constructor(
    public productosService: ProductoService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
    this.EditProductosForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
      cantidad: ['']
    })

  }
  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.productosService.getProductos(id).subscribe((data: Producto[]) => {
      this.productoRef = data[0];
      this.EditProductosForm = this.formBuilder.group({
        nombre: [this.productoRef.nombre],
        descripcion: [this.productoRef.descripcion],
        valor: [this.productoRef.valor],
        cantidad: [this.productoRef.cantidad]
      })
    });
  }
  
  onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.productosService.updateProducto(this.EditProductosForm.value,id).subscribe((res) => {
      this.router.navigate(['/listarProductos'])
    });
  }
}
