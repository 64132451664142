import { Component, OnInit } from '@angular/core';
import { Usuario } from '@models/usuario.model';
import { UsuarioService } from '@services/usuario.service';
import { Router, ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {
  Usuarios: Usuario[]
    constructor(private usuarioService: UsuarioService,
      private activatedRoute: ActivatedRoute){}
    
    ngOnInit(): void {
      this.usuarioService.getUsuarios(null).subscribe((data: Usuario[]) => {
        this.Usuarios = data;
      });
    }
  
    deleteRow = (usuario) => {
      this.usuarioService.deletelUsuarioLogic(usuario.id).subscribe(() => {
        this.usuarioService.getUsuarios(null).subscribe((data: Usuario[]) => {
          this.Usuarios = data;
        });
      });
    } 
  }
  