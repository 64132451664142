import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Abono } from '@models/abono.model';
import { AbonoService } from '@services/abono.service';

@Component({
  selector: 'app-listarabonos',
  templateUrl: './listarabonos.component.html',
  styleUrls: ['./listarabonos.component.scss']
})
export class ListarabonosComponent {
  Abonos: Abono[]
  id;

    constructor(private abonoService: AbonoService,
      private activatedRoute: ActivatedRoute){}
    
    ngOnInit(): void {
      this.id = this.activatedRoute.snapshot.paramMap.get('id')
      this.abonoService.getAbonos(this.id).subscribe((data: Abono[]) => {
        console.log(data);
        this.Abonos = data;
      },(err ) => {
        console.log(err);
      });
    }
  
    deleteRow = (abono) => {
      this.abonoService.deleteAbonoLogic(abono.id).subscribe((res) => {
        this.abonoService.getAbonos(this.id).subscribe((data: Abono[]) => {
          this.Abonos = data;
        });
      });
    } 

}
