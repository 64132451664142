import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Venta } from '@models/venta.model';
import { VentaService } from '@services/venta.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-listarventas',
  templateUrl: './listarventas.component.html',
  styleUrls: ['./listarventas.component.scss']
})
export class ListarventasComponent {
  Ventas: Venta[]
    constructor(private ventaService: VentaService,
      private activatedRoute: ActivatedRoute){}
    
    ngOnInit(): void {
      this.ventaService.getVentas(null).subscribe((data: Venta[]) => {
        console.log(data);
        this.Ventas = data;
      },(err ) => {
        console.log(err);
      });
    }
  
    deleteRow = (venta) => {
      this.ventaService.deleteVentaLogic(venta.id).subscribe((res) => {
        this.ventaService.getVentas(null).subscribe((data: Venta[]) => {
          this.Ventas = data;
        });
      });
    } 

    exportToExcel(): void {
      const fileName = 'ventas_coquena.xlsx';
      const worksheetName = 'Datos';
  
      const data: any[] = this.Ventas.map(row => Object.values(row));
      const header: any[] = Object.keys(this.Ventas[0]);
  
      const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header, ...data]);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  
      // Generar el archivo y descargarlo
      XLSX.writeFile(workbook, fileName);
    }
}
