<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Crear Gasto</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="CompraForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Nombre:</label>
                <input type="text" formControlName="nombre" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Descripcion:</label>
                <input type="text" formControlName="descripcion" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Empresa:</label>
                <input type="text" formControlName="empresa" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Usuario:</label>
                <p>
                    <select class="custom-select" formControlName="usuario" name="miselect" required>
                        <option>Seleecione Usuario</option>
                        <option [value]="usuario.id" *ngFor="let usuario of Usuarios">{{usuario.nombre}}</option>
                    </select>
                </p>
            </div>
            <div class="mb-3">
                <label class="form-label">Medio Pago:</label>
                <p>
                    <select class="custom-select" formControlName="medio_pago" name="miselect" required>
                        <option>Seleecione Medio de Pago</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="Debito">Debito</option>
                        <option value="Credito">Credito</option>
                        <option value="Deuda">Deuda</option>
                    </select>
                </p>
            </div>


            <div class="mb-3">
                <label class="form-label">Evidencia:</label>
                <div class="input-group">
                    <div class="custom-file">
                        <input id="file"
                        (change)="fileChange($event)" 
                        type="file" placeholder="File">
                        <label class="custom-file-label" for="file">Seleccione Archivo</label>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label">Fecha de Compra:</label>
                <input #myInput formControlName="fecha_edicion" type="text" class="form-control" required/>
            </div>

            <button [disabled]="!CompraForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>
    </div>
</div>

