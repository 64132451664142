import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from '@models/compra.model';
import { Compramaterial } from '@models/compramaterial.model';
import { Material } from '@models/material.model';
import { Usuario } from '@models/usuario.model';
import { CompraService } from '@services/compra.service';
import { CompramaterialService } from '@services/compramaterial.service';
import { ImageService } from '@services/image.service';
import { MaterialService } from '@services/material.service';
import { UsuarioService } from '@services/usuario.service';

import Inputmask from 'inputmask';

@Component({
  selector: 'app-editarcompras',
  templateUrl: './editarcompras.component.html',
  styleUrls: ['./editarcompras.component.scss']
})
export class EditarcomprasComponent {
  public EditComprasForm: FormGroup;
  comprasRef: any
  public comprasMateriales;
  id;
  public Usuarios;
  file_data:any='';
  
  @ViewChild('myInput') myInputElementRef: ElementRef;

  constructor(
    public compraService: CompraService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public compraMaterialService:CompramaterialService,
    public materialesService: MaterialService,
    public usuariosService:UsuarioService,
    public imageService:ImageService
  ){
    this.EditComprasForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
      usuario: [''],
      nombre_usuario: [''],
      medio_pago: [''],
      empresa: [''],
      imagen: [''],
      fecha_edicion:[''],
    })
  }

  ngAfterViewInit(): void {
    Inputmask('datetime', {
    inputFormat: 'yyyy/mm/dd',
    placeholder: 'yyyy/mm/dd',
    alias: 'datetime',
    min: '2020/01/01',
    clearMaskOnLostFocus: false,
    isComplete: function(buffer, opts) {
    }
  }).mask(this.myInputElementRef.nativeElement);
}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.compraService.getCompras(this.id).subscribe((data: Compra[]) => {
      this.comprasRef = data[0];
      this.EditComprasForm = this.formBuilder.group({
        nombre: [this.comprasRef.nombre],
        descripcion: [this.comprasRef.descripcion],
        valor: [this.comprasRef.valor],
        usuario: [this.comprasRef.usuario],
        medio_pago: [this.comprasRef.medio_pago],
        empresa: [this.comprasRef.empresa],
        imagen: [this.comprasRef.imagen],
        fecha_edicion: [this.comprasRef.fecha_edicion],
      })

      this.usuariosService.getUsuarios(null).subscribe((data: Usuario[]) => {
        this.Usuarios = data;
      });

      this.compraMaterialService.getComprasMateriales(this.id,null).subscribe((data: Compramaterial[]) => {
        this.comprasMateriales = data;
      });
    });
  }

 onSubmit(){
    this.usuariosService.getUsuarios(this.EditComprasForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.EditComprasForm.value.nombre_usuario = usuario.nombre;
      this.compraService.updateCompra(this.EditComprasForm.value,this.id).subscribe((res) => {
        this.router.navigate(['/listarCompras'])
      });
    });
  }


  deleteRow = (compraMaterial) => {
    this.materialesService.getMateriales(compraMaterial.idMaterial,null).subscribe((data: Material[]) => {
      let materialSeleccionado = data[0];
      
      console.log(materialSeleccionado);

      var cant =  materialSeleccionado.cantidad.toString();
      var cantFinal;
      var tempValor = compraMaterial.cantidad.toString();
      //devuelve al valor original
      cantFinal = parseInt(cant) - parseInt(tempValor);
      materialSeleccionado.cantidad = cantFinal;
      this.materialesService.updateMaterial(materialSeleccionado,materialSeleccionado.id).subscribe((res) => {


        this.compraService.getCompras(compraMaterial.idCompra).subscribe((data: Compra[]) => {
          let compraSeleccionada = data[0];
    
          var valor =  compraSeleccionada.valor.toString();
          var valorFinal;
          var tempo = compraMaterial.valor.toString();
          //devuelve al valor original
          valorFinal = parseInt(valor) - parseInt(tempo);
          compraSeleccionada.valor = valorFinal;

          this.EditComprasForm = this.formBuilder.group({
            nombre: [this.comprasRef.nombre],
            descripcion: [this.comprasRef.descripcion],
            valor: [valorFinal],
          })

          this.compraService.updateCompra(compraSeleccionada,compraSeleccionada.id).subscribe((res) => {
              this.compraMaterialService.deleteCompraMaterialLogic(compraMaterial.id).subscribe((res) => {
                this.compraMaterialService.getComprasMateriales(null,null).subscribe((data: Compramaterial[]) => {
                  this.comprasMateriales = data;
                });
              });
            });
        });
        });
    });
  }
  
  fileChange(event) {
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

        const file = fileList[0];
        //get file information such as name, size and type
        console.log('finfo',file.name,file.size,file.type);
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          this.file_data=formData
          this.imageService.uploadImage(this.file_data).subscribe( res => {
            console.log(res);
            this.EditComprasForm.value.imagen = res;
          }, (err) => {
            console.log(err);
          });
        }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
    }
  }
}
