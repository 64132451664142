import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MaterialService } from '@services/material.service';

@Component({
  selector: 'app-agregarmaterial',
  templateUrl: './agregarmaterial.component.html',
  styleUrls: ['./agregarmaterial.component.scss']
})
export class AgregarmaterialComponent {
  MaterialForm: FormGroup;
  constructor(
    public materialService: MaterialService,
    public formBuilder: FormBuilder,
    private router: Router
  ){ 
    this.MaterialForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      cantidad: [0],
      valor: [0],
      borrado: [0],
      fecha: [''],
      tipo: [1]
    })
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.materialService.createMaterial(this.MaterialForm.value).subscribe((res) => {
      this.router.navigate(['/listarMateriales'])
    });
  }
}
