import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@models/usuario.model';
import { AbonoService } from '@services/abono.service';
import { UsuarioService } from '@services/usuario.service';
import Inputmask from 'inputmask';


@Component({
  selector: 'app-agregarabono',
  templateUrl: './agregarabono.component.html',
  styleUrls: ['./agregarabono.component.scss']
})
export class AgregarabonoComponent {
  public AbonoForm: FormGroup;
  public Usuarios;
  id;

  @ViewChild('myInput') myInputElementRef: ElementRef;

  constructor(
    public abonoService: AbonoService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public usuariosService:UsuarioService
  ){ 
    this.AbonoForm = this.formBuilder.group({
      descripcion: [''],
      valor: [0],
      borrado: [0],
      fecha: [''],
      usuario: [0],
      nombre_usuario: [''],
      medio_pago: [''],
      fecha_edicion:['']
    })
  }


  ngAfterViewInit(): void {
    Inputmask('datetime', {
      inputFormat: 'yyyy/mm/dd',
      placeholder: 'yyyy/mm/dd',
      alias: 'datetime',
      min: '2020/01/01',
      clearMaskOnLostFocus: false,
      isComplete: function(buffer, opts) {
      }
    }).mask(this.myInputElementRef.nativeElement);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.usuariosService.getUsuarios(null).subscribe((data: Usuario[]) => {
      this.Usuarios = data;
    });
  }

  onSubmit(){
    this.usuariosService.getUsuarios(this.AbonoForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.AbonoForm.value.nombre_usuario = usuario.nombre;
      this.AbonoForm.value.id_venta =  this.id;
      this.abonoService.createAbono(this.AbonoForm.value).subscribe((res) => {
        console.log(res);
        this.router.navigate(['/listarAbonos/'+this.id])
      });
    });
  }
}
