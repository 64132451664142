import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Compra } from '@models/compra.model';
import { MaterialService } from './material.service';

@Injectable({
  providedIn: 'root'
})
export class CompraService {
  materialesRef: any
  constructor(private angulafirestore: AngularFirestore,
              public materialesService:MaterialService,
              public http: HttpClient) { }

  getCompras(id){

    const data = {
      id: id,
    };
    return this.http.post<Compra[]>('https://inclunova.cl/sgiCoquena/compraGet.php',data);
  }

  getComprasEstadistica(id){
    const data = {
      id: id,
    };
    return this.http.post<Compra[]>('https://inclunova.cl/sgiCoquena/compraGetEstadistica.php',data);
  }

  createCompra(compra:Compra,imagen){
    let link= 'https://inclunova.cl/sgiCoquena/compraInsert.php';
    let data = {
      nombre:compra.nombre,
      descripcion: compra.descripcion,
      valor: compra.valor,
      usuario:compra.usuario,
      nombre_usuario:compra.nombre_usuario,
      medio_pago:compra.medio_pago,
      empresa:compra.empresa,
      imagen:imagen,
      fecha_edicion:compra.fecha_edicion
    };
    return this.http.post(link, data);
  }

  updateCompra(compra:Compra,id){
    let link= 'https://inclunova.cl/sgiCoquena/compraUpdate.php';
    let data = {
      id:id,
      nombre:compra.nombre,
      descripcion: compra.descripcion,
      valor: compra.valor,
      usuario:compra.usuario,
      nombre_usuario:compra.nombre_usuario,
      medio_pago:compra.medio_pago,
      empresa:compra.empresa,
      imagen:compra.imagen,
      fecha_edicion:compra.fecha_edicion
    };
    return this.http.post(link, data);
  }

  deleteCompraLogic(id){
      let link= 'https://inclunova.cl/sgiCoquena/compraDelete.php';
      let data = {
          id:id
      };
      return this.http.post(link, data)
  }
            
      // //obtener
      // getCompras(){
      //   return this.angulafirestore
      //   .collection("compras", ref => ref.where('borrado', '!=', 1))
      //   .snapshotChanges()
      // }
      // getComprasId(id){
      //   return this.angulafirestore
      //   .collection("compras")
      //   .doc(id)
      //   .valueChanges()
      // }
    
      // createCompras(compra:Compra){
      //   this.materialesService.getMaterialId(compra.material).subscribe( res => {
      //     this.materialesRef = res
      //     compra.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //     compra.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //     compra.nombre_material =  this.materialesRef.nombre;
      //     return new Promise<any> (( resolve, reject ) =>{
      //       this.angulafirestore
      //       .collection("compras")
      //       .add(compra)
      //       .then( (Response) => {
      //         console.log(Response)
      //       },
      //       (error) => {
      //         reject(error)
      //       })
      //     }) 
      //   })
      // }
    
      // updateCompras(compra: Compra, id){

      //   this.materialesService.getMaterialId(compra.material).subscribe( res => {
      //     this.materialesRef = res
      //     compra.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //     compra.nombre_material =  this.materialesRef.nombre;
      //     return this.angulafirestore
      //     .collection("compras")
      //     .doc(id)
      //     .update({
      //       nombre: compra.nombre,
      //       descripcion: compra.descripcion,
      //       valor: compra.valor,
      //       cantidad: compra.cantidad,
      //       material: compra.material,
      //       nombre_material:compra.nombre_material
      //     })
      //   })
      // }

      // deleteCompraLogic(compra: Compra, id){
      //   return this.angulafirestore
      //   .collection("compras")
      //   .doc(id)
      //   .update({
      //     borrado: compra.borrado = 1
      //   })
      // }

      // deleteCompra(compra){
      //   return this.angulafirestore
      //   .collection("compras")
      //   .doc(compra.id)
      //   .delete()
      // }
}
