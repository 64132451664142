import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Compra } from '@models/compra.model';
import { CompraService } from '@services/compra.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-listarcompras',
  templateUrl: './listarcompras.component.html',
  styleUrls: ['./listarcompras.component.scss']
})
export class ListarcomprasComponent {
  Compras: Compra[]
    constructor(private compraService: CompraService,
      private activatedRoute: ActivatedRoute){}
    
    ngOnInit(): void {
      this.compraService.getCompras(null).subscribe((data: Compra[]) => {
        this.Compras = data;
      });
    }
  
    deleteRow = (compra) => {
      this.compraService.deleteCompraLogic(compra.id).subscribe(() => {
        this.compraService.getCompras(null).subscribe((data: Compra[]) => {
          this.Compras = data;
        });
      });
    } 

    exportToExcel(): void {
      const fileName = 'gastos_coquena.xlsx';
      const worksheetName = 'Datos';
  
      const data: any[] = this.Compras.map(row => Object.values(row));
      const header: any[] = Object.keys(this.Compras[0]);
  
      const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([header, ...data]);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
  
      // Generar el archivo y descargarlo
      XLSX.writeFile(workbook, fileName);
    }
}
