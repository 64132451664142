import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeudaService } from '@services/deuda.service';
import { PagoService } from '@services/pago.service';

@Component({
  selector: 'app-editarpagos',
  templateUrl: './editarpagos.component.html',
  styleUrls: ['./editarpagos.component.scss']
})
export class EditarpagosComponent {
  public EditPagosForm: FormGroup;
  pagosRef: any
  valorInicial;
  deudaRef: any
  constructor(
    public pagoService: PagoService,
    public deudaService: DeudaService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
    this.EditPagosForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
    })

  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.pagoService.getPagos(id,null).subscribe( res => {
      this.pagosRef = res[0];
      this.valorInicial = this.pagosRef.valor;
      this.deudaService.getDeudas(this.pagosRef.id_deuda).subscribe( res => {
        this.deudaRef = res[0];
      })

      this.EditPagosForm = this.formBuilder.group({
        nombre: [this.pagosRef.nombre],
        descripcion: [this.pagosRef.descripcion],
        valor: [this.pagosRef.valor],
      })
    })
  }

  onSubmit(){
    var tempValor = this.EditPagosForm.value.valor.toString();
    var valor_pagado_deuda = this.deudaRef.valor_pagado.toString();
    let tempValorRes =  this.valorInicial.toString();
    this.deudaRef.valor_pagado= parseInt(valor_pagado_deuda)  - parseInt(tempValorRes) + parseInt(tempValor);
    this.deudaService.updateDeuda(this.deudaRef,this.pagosRef.id_deuda).subscribe((res) => {
      const id = this.activatedRoute.snapshot.paramMap.get('id')
      this.pagoService.updatePago(this.EditPagosForm.value,id).subscribe((resPago) => {
        this.router.navigate(['/editarDeudas/' + this.pagosRef.id_deuda])
      })
    });
  }
}
