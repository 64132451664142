<div class="content">
  <div class="container-fluid">
    
    <!-- TOTALES ACUMULADOS -->
    <div class="row">
        <div class="col-lg-3 col-6">
          <div class="small-box bg-info">
              <div class="inner">
                  <h3>{{totalVentasMostrarAcumulado}}</h3>
                  <p>Ventas Acumulado</p>
              </div>
              <div class="icon">
                  <i class="ion ion-cash"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-warning">
              <div class="inner">
                  <h3>{{totalComprasMostrarAcumulado}}</h3>
                  <p>Gastos Acumulado</p>
              </div>
              <div class="icon">
                  <i class="ion ion-ios-cart"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-success">
              <div class="inner">
                  <h3>{{utilidadMostrarAcumulado}}</h3>
                  <p>Utilidad Acumulada</p>
              </div>
              <div class="icon">
                  <i class="ion ion-stats-bars"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>

        <div class="col-lg-3 col-6">
          <!-- small box -->
          <div class="small-box bg-danger">
              <div class="inner">
                  <h3>{{utilidadPorcentualAcumulado}}<sup style="font-size: 20px">%</sup></h3>
                  <p>Utilidad Porcentual</p>
              </div>
              <div class="icon">
                  <i class="ion ion-pie-graph"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
          </div>
        </div>
    </div>
    <!-- TOTALES ACUMULADOS -->

    <!-- TOTALES MES -->
    <div class="row">
      <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
            <div class="inner">
                <h3>{{totalVentasMostrarMes}}</h3>
                <p>Ventas Mes Actual</p>
            </div>
            <div class="icon">
                <i class="ion ion-cash"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-warning">
            <div class="inner">
                <h3>{{totalComprasMostrarMes}}</h3>
                <p>Gastos Mes Actual</p>
            </div>
            <div class="icon">
                <i class="ion ion-ios-cart"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-success">
            <div class="inner">
                <h3>{{utilidadMostrarMes}}</h3>
                <p>Utilidad Mes Actual</p>
            </div>
            <div class="icon">
                <i class="ion ion-stats-bars"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>

      <div class="col-lg-3 col-6">
        <!-- small box -->
        <div class="small-box bg-danger">
            <div class="inner">
                <h3>{{utilidadPorcentualMes}}<sup style="font-size: 20px">%</sup></h3>
                <p>Utilidad Mes Actual</p>
            </div>
            <div class="icon">
                <i class="ion ion-pie-graph"></i>
            </div>
            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        </div>
      </div>
    </div>
    <!-- TOTALES MES -->
    
    <div class="row">
      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Ventas / Gastos</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas #myChartBar></canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Ventas con Deuda</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasVentaDeuda">{{chartVentaDeuda}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Stock Productos</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasBarStock">{{chartBarStock}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Stock Insumos</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasBarStockInsumos">{{chartBarStockInsumos}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Productos más Vendidos</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasProductosVendidos">{{chartProductosVendidos}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Insumos más Comprados</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasMaterialesComprados">{{chartMaterialesComprados}}</canvas>
            </div>
          </div>
        </div>
      </div>



      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Deudas / Pagos</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasDeudas">{{chartDeudas}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">Tareas Realizadas / Tareas Pendientes</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas id="canvasTareas">{{chartTareas}}</canvas>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title">Tareas Pendientes</h3>
              <!-- <div class="card-tools">
                <a href="#" class="btn btn-tool btn-sm">
                  <i class="fas fa-download"></i>
                </a>
                <a href="#" class="btn btn-tool btn-sm">
                  <i class="fas fa-bars"></i>
                </a>
              </div> -->
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>Tarea</th>
                    <th>Responsable</th>
                    <th>Fecha</th>
                    <th>Ver Tarea</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tarea of Tareas">
                    <td> {{tarea.nombre}} </td>
                    <td> {{tarea.nombre_usuario}} </td>
                    <td> {{tarea.fecha_creacion}} </td>
                    <td>
                      <a routerLink="/editarTareas/{{tarea.id}}" class="text-muted">
                        <i class="fas fa-search"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>

      <div class="col-md-6">
        <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title">Productos Con Menor Stock</h3>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped table-valign-middle">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th>Reponer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let producto of Productos">
                    <td> {{producto.nombre}} </td>
                    <td> {{producto.cantidad}} </td>
                    <td>
                      <a routerLink="/editarProductos/{{producto.id}}" class="text-muted">
                        <i class="fas fa-search"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      </div>



      <!-- <div class="col-md-6">
        <div class="card card-success">
          <div class="card-header">
            <h3 class="card-title">GRAFICO DOUGHNUT</h3>
          </div>
          <div class="card-body">
            <div style="display: block;">
              <canvas baseChart [labels]="doughnutChartLabels" [datasets]="doughnutChartDatasets"
                [options]="doughnutChartOptions" [legend]="true" [type]="'doughnut'">
              </canvas>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>