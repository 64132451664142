import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ventaproducto } from '@models/ventaproducto.model';

@Injectable({
  providedIn: 'root'
})
export class VentaproductoService {

  constructor(public http: HttpClient) { }

  getVentasProductos(idVenta,id){
    const data = {
      id: id,
      idVenta:idVenta
    };
    return this.http.post<Ventaproducto[]>('https://inclunova.cl/sgiCoquena/ventaProductoGet.php',data);
  }

  getVentasProductosEstadistica(idVenta,id){
    const data = {
      id: id,
      idVenta:idVenta
    };
    return this.http.post<Ventaproducto[]>('https://inclunova.cl/sgiCoquena/ventaProductoGetEstadistica.php',data);
  }


  createVentaProducto(ventaProducto:Ventaproducto){
    let link= 'https://inclunova.cl/sgiCoquena/ventaProductoInsert.php';
    let data = {
      idVenta:ventaProducto.idVenta,
      idProducto:ventaProducto.idProducto,
      nombre:ventaProducto.nombre,
      cantidad: ventaProducto.cantidad,
      valor: ventaProducto.valor
    };
    return this.http.post(link, data);
  }

  updateVentaProducto(ventaProducto:Ventaproducto,id){
    let link= 'https://inclunova.cl/sgiCoquena/ventaProductoUpdate.php';
    let data = {
      id:id,
      cantidad: ventaProducto.cantidad,
      valor: ventaProducto.valor
    };
    return this.http.post(link, data);
  }

  deleteVentaProductoLogic(id){
    let link= 'https://inclunova.cl/sgiCoquena/ventaProductoDelete.php';
    let data = {
        id:id
    };
    return this.http.post(link, data)
  }
}
