import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Producto } from '@models/producto.model';
import { Usuario } from '@models/usuario.model';
import { ImageService } from '@services/image.service';
import { ProductoService } from '@services/producto.service';
import { UsuarioService } from '@services/usuario.service';
import { VentaService } from '@services/venta.service';

import Inputmask from 'inputmask';

@Component({
  selector: 'app-agregarventas',
  templateUrl: './agregarventas.component.html',
  styleUrls: ['./agregarventas.component.scss']
})
export class AgregarventasComponent {
  public VentaForm: FormGroup;
  public Productos;
  public Usuarios;
  imagen;

  @ViewChild('myInput') myInputElementRef: ElementRef;

  file_data:any='';

  constructor(
    public ventaService: VentaService,
    public formBuilder: FormBuilder,
    private router: Router,
    public productoService:ProductoService,
    public usuariosService:UsuarioService,
    private imageService: ImageService
  ){ 
    this.VentaForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [0],
      borrado: [0],
      fecha: [''],
      usuario: [0],
      nombre_usuario: [''],
      medio_pago: [''],
      imagen: [''],
      fecha_edicion:['']
    })
  }

  ngAfterViewInit(): void {
    Inputmask('datetime', {
      inputFormat: 'yyyy/mm/dd',
      placeholder: 'yyyy/mm/dd',
      alias: 'datetime',
      min: '2020/01/01',
      clearMaskOnLostFocus: false,
      isComplete: function(buffer, opts) {
      }
    }).mask(this.myInputElementRef.nativeElement);
  }

  ngOnInit(): void {
    this.usuariosService.getUsuarios(null).subscribe((data: Usuario[]) => {
      this.Usuarios = data;
    });
  }
  
  onSubmit(){
    this.usuariosService.getUsuarios(this.VentaForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.VentaForm.value.nombre_usuario = usuario.nombre;
      this.ventaService.createVenta(this.VentaForm.value,this.imagen).subscribe((res) => {
        console.log(res);
        this.router.navigate(['/listarVentas'])
      });
    });
  }


  fileChange(event) {
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

        const file = fileList[0];
        //get file information such as name, size and type
        console.log('finfo',file.name,file.size,file.type);
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          this.file_data=formData
          this.imageService.uploadImage(this.file_data).subscribe( res => {
            this.imagen = res;
            console.log(this.imagen);
          }, (err) => {
            console.log(err);
          });
        }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
    }
  }
}

