import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from '@models/compra.model';
import { Material } from '@models/material.model';
import { CompraService } from '@services/compra.service';
import { CompramaterialService } from '@services/compramaterial.service';
import { MaterialService } from '@services/material.service';

@Component({
  selector: 'app-agregarcomprasmateriales',
  templateUrl: './agregarcomprasmateriales.component.html',
  styleUrls: ['./agregarcomprasmateriales.component.scss']
})
export class AgregarcomprasmaterialesComponent {
  public CompraMaterialForm: FormGroup;
  public Materiales;
  idCompra;
  constructor(
    public materialService: MaterialService,
    public compraService: CompraService,
    public compraMaterialService: CompramaterialService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ){ 
    this.idCompra = this.activatedRoute.snapshot.paramMap.get('id')
    this.CompraMaterialForm = this.formBuilder.group({
      nombre: [''],
      cantidad: [0],
      valor: [0],
      idCompra: this.idCompra,
      idMaterial: [0]
    })
  }

  ngOnInit(): void {
    this.materialService.getMateriales(null,1).subscribe((data: Material[]) => {
      this.Materiales = data;
    });
  }

  onSubmit(){
    this.materialService.getMateriales(this.CompraMaterialForm.value.idMaterial,1).subscribe((data: Material[]) => {
      let materialSeleccionado = data[0];
      this.CompraMaterialForm.value.nombre = materialSeleccionado.nombre;

       var cant =  materialSeleccionado.cantidad.toString();
       var cantFinal;
       var tempValorRes =  this.CompraMaterialForm.value.cantidad.toString();
  
       cantFinal= parseInt(cant)  + parseInt(tempValorRes);

       materialSeleccionado.cantidad = cantFinal;
       this.materialService.updateMaterial(materialSeleccionado,materialSeleccionado.id).subscribe((res) => {


          this.compraService.getCompras(this.CompraMaterialForm.value.idCompra).subscribe((data: Compra[]) => {
            let compraSeleccionada = data[0];

            var valor =  compraSeleccionada.valor.toString();
            var valorFinal;
            var tempoValor =  this.CompraMaterialForm.value.valor.toString();
        
            valorFinal= parseInt(valor)  + parseInt(tempoValor);

            compraSeleccionada.valor = valorFinal;
            this.compraService.updateCompra(compraSeleccionada,compraSeleccionada.id).subscribe((res) => {
                this.compraMaterialService.createCompraMaterial(this.CompraMaterialForm.value).subscribe((res) => {
                  this.router.navigate(['/editarCompras/'+this.idCompra])
                });
              });
          });
        });
    });
  }
}
