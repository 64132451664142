<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Agregar Abono</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="AbonoForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Descripcion:</label>
                <input type="text" formControlName="descripcion" type="text" class="form-control" required>
            </div>

            <div class="mb-3">
                <label class="form-label">Valor Abono:</label>
                <input type="text" formControlName="valor" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Usuario:</label>
                <p>
                    <select class="custom-select" formControlName="usuario" name="miselect" required>
                        <option>Seleecione Usuario</option>
                        <option [value]="usuario.id" *ngFor="let usuario of Usuarios">{{usuario.nombre}}</option>
                    </select>
                </p>
            </div>
            <div class="mb-3">
                <label class="form-label">Medio Pago:</label>
                <p>
                    <select class="custom-select" formControlName="medio_pago" name="miselect" required>
                        <option>Seleecione Medio de Pago</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="Debito">Debito</option>
                        <option value="Credito">Credito</option>
                        <option value="Cheque">Cheque</option>
                    </select>
                </p>
            </div>


            <div class="mb-3">
                <label class="form-label">Fecha de Abono:</label>
                <input #myInput formControlName="fecha_edicion" type="text" class="form-control" required/>
            </div>

            <button [disabled]="!AbonoForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>
    </div>
</div>

