import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DeudaService } from '@services/deuda.service';

@Component({
  selector: 'app-agregardeudas',
  templateUrl: './agregardeudas.component.html',
  styleUrls: ['./agregardeudas.component.scss']
})
export class AgregardeudasComponent {
  public DeudaForm: FormGroup;

  constructor(
    public deudaService: DeudaService,
    public formBuilder: FormBuilder,
    private router: Router
  ){ 
    this.DeudaForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [0],
      valor_pagado: [0],
      borrado: [0],
      fecha: [''],
    })
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.deudaService.createDeuda(this.DeudaForm.value).subscribe((res) => {
      this.router.navigate(['/listarDeudas'])
    });
  }
}
