<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Editar Deuda</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="EditDeudaForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Nombre:</label>
                <input type="text" formControlName="nombre" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Descripcion:</label>
                <input type="text" formControlName="descripcion" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Valor:</label>
                <input type="text" formControlName="valor" type="text" class="form-control" required readonly>
            </div>
            <div class="mb-3">
                <label class="form-label">Valor Pagado:</label>
                <input type="text" formControlName="valor_pagado" type="text" class="form-control" required readonly>
            </div>
            <button [disabled]="!EditDeudaForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>

        <br>
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Pagos de la  Deuda</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <button routerLink="/agregarPagos/{{id}}" class="btn btn-success" type="button">
                                    <i class="fas fa-plus-circle"></i> Agregar Pago a la Deuda
                                </button>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Valor Pago</th>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Fecha Edición</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let pago of Pagos">
                    <td scope="row">  </td>
                    <td> {{pago.nombre}} </td>
                    <td> {{pago.descripcion}} </td>
                    <td> {{pago.valor}} </td>
                    <td> {{pago.fecha_creacion}} </td>
                    <td> {{pago.fecha_edicion}} </td>
                    <td>
                        <button routerLink="/editarPagos/{{pago.id}}" class="btn btn-outline-secondary"> <i class="fas fa-pen"></i></button>
                        <button (click)="deleteRow(pago)"class="btn btn-outline-danger"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>