<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Agregar Producto a la Venta</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="VentaProductoForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Valor:</label>
                <input type="text" formControlName="valor" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Cantidad:</label>
                <input type="text" formControlName="cantidad" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Producto:</label>
                <p>
                    <select class="custom-select" formControlName="idProducto" name="miselect" required>
                        <option>Seleecione Producto</option>
                        <option [value]="producto.id" *ngFor="let producto of Productos">{{producto.nombre}}</option>
                    </select>
                </p>
            </div>
            <button [disabled]="!VentaProductoForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>
    </div>
</div>

