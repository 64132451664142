import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Abono } from '@models/abono.model';

@Injectable({
  providedIn: 'root'
})
export class AbonoService {

  constructor(public http: HttpClient) { }


  getAbonos(id){
    const data = {
      id: id,
    };
    return this.http.post<Abono[]>('https://inclunova.cl/sgiCoquena/abonoGet.php',data);
  }


  createAbono(abono:Abono){
    let link= 'https://inclunova.cl/sgiCoquena/abonoInsert.php';
    let data = {
      id_venta: abono.id_venta,
      descripcion: abono.descripcion,
      valor: abono.valor,
      usuario:abono.usuario,
      nombre_usuario:abono.nombre_usuario,
      medio_pago:abono.medio_pago,
      fecha_edicion:abono.fecha_edicion
    };
    console.log(data);
    return this.http.post(link, data);
  }


  deleteAbonoLogic(id){
    let link= 'https://inclunova.cl/sgiCoquena/abonoDelete.php';
    let data = {
        id:id
    };
    return this.http.post(link, data)
  }

}
