import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductoService } from '@services/producto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Producto } from '@models/producto.model';
import { VentaproductoService } from '@services/ventaproducto.service';
import { VentaService } from '@services/venta.service';
import { Venta } from '@models/venta.model';

@Component({
  selector: 'app-agregarventasproductos',
  templateUrl: './agregarventasproductos.component.html',
  styleUrls: ['./agregarventasproductos.component.scss']
})
export class AgregarventasproductosComponent {
  public VentaProductoForm: FormGroup;
  public Productos;
  idVenta;
  constructor(
    public productoService: ProductoService,
    public ventaService: VentaService,
    public ventaProductoService: VentaproductoService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ){ 
    this.idVenta = this.activatedRoute.snapshot.paramMap.get('id')
    this.VentaProductoForm = this.formBuilder.group({
      nombre: [''],
      cantidad: [0],
      valor: [0],
      idVenta: this.idVenta,
      idProducto: [0]
    })
  }

  ngOnInit(): void {
    this.productoService.getProductos(null).subscribe((data: Producto[]) => {
      this.Productos = data;
    });
  }

  onSubmit(){
    this.productoService.getProductos(this.VentaProductoForm.value.idProducto).subscribe((data: Producto[]) => {
      let productoSeleccionado = data[0];
      this.VentaProductoForm.value.nombre = productoSeleccionado.nombre;

       let cant =  productoSeleccionado.cantidad;
       let tempValorRes =  this.VentaProductoForm.value.cantidad.toString();
  
       cant= cant  - parseInt(tempValorRes);

       productoSeleccionado.cantidad = cant;
       this.productoService.updateProducto(productoSeleccionado,productoSeleccionado.id).subscribe((res) => {


          this.ventaService.getVentas(this.VentaProductoForm.value.idVenta).subscribe((data: Venta[]) => {
            let ventaSeleccionada = data[0];

            var valor =  ventaSeleccionada.valor.toString();
            var valorFinal;
            var tempoValor =  this.VentaProductoForm.value.valor.toString();
        
            valorFinal= parseInt(valor)  + parseInt(tempoValor);

            ventaSeleccionada.valor = valorFinal;
            this.ventaService.updateVenta(ventaSeleccionada,ventaSeleccionada.id).subscribe((res) => {
                this.ventaProductoService.createVentaProducto(this.VentaProductoForm.value).subscribe((res) => {
                  this.router.navigate(['/editarVentas/'+this.idVenta])
                });
              });
          });
        });
    });
  }
}
