import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Producto } from '@models/producto.model';
import { Venta } from '@models/venta.model';
import { Ventaproducto } from '@models/ventaproducto.model';
import { ProductoService } from '@services/producto.service';
import { VentaService } from '@services/venta.service';
import { VentaproductoService } from '@services/ventaproducto.service';

@Component({
  selector: 'app-editarventasproductos',
  templateUrl: './editarventasproductos.component.html',
  styleUrls: ['./editarventasproductos.component.scss']
})
export class EditarventasproductosComponent {
  public EditVentaProductoForm: FormGroup;
  ventaProductoRef: any;
  id;
  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ventasProductoService: VentaproductoService,
    public productoService: ProductoService,
    public ventaService: VentaService
  ){
    this.EditVentaProductoForm = this.formBuilder.group({
      cantidad: [''],
      valor: [''],
    })
  }


  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.ventasProductoService.getVentasProductos(null,this.id).subscribe((data: Ventaproducto[]) => {
      this.ventaProductoRef = data[0];
      this.EditVentaProductoForm = this.formBuilder.group({
        cantidad: [this.ventaProductoRef.cantidad],
        valor: [this.ventaProductoRef.valor],
      })
    });
  }

 onSubmit(){
    this.productoService.getProductos(this.EditVentaProductoForm.value.idProducto).subscribe((data: Producto[]) => {
      let productoSeleccionado = data[0];

      var cant =  productoSeleccionado.cantidad.toString();
      var cantTemp;
      var cantFinal;
      var tempValorRes =  this.EditVentaProductoForm.value.cantidad;
      var tempValor = this.ventaProductoRef.cantidad.toString();
      //devuelve al valor original
      cantTemp = parseInt(cant) + parseInt(tempValor);
      cantFinal = parseInt(cantTemp) - parseInt(tempValorRes);
      productoSeleccionado.cantidad = cantFinal;
      this.productoService.updateProducto(productoSeleccionado,productoSeleccionado.id).subscribe((res) => {
          // this.ventasProductoService.updateVentaProducto(this.EditVentaProductoForm.value,this.id).subscribe((res) => {
          //   this.router.navigate(['/editarVentas/'+this.ventaProductoRef.idVenta])
          // });

          this.ventaService.getVentas(this.EditVentaProductoForm.value.idVenta).subscribe((data: Venta[]) => {
            let ventaSeleccionada = data[0];
      
            var valor =  ventaSeleccionada.valor.toString();
            var valorTempo;
            var valorFinal;
            var tempoValor =  this.EditVentaProductoForm.value.valor;
            var tempoValorRes = this.ventaProductoRef.valor.toString();
            //devuelve al valor original
            valorTempo = parseInt(valor) - parseInt(tempoValorRes);
            valorFinal = parseInt(valorTempo) + parseInt(tempoValor);
            ventaSeleccionada.valor = valorFinal;
            this.ventaService.updateVenta(ventaSeleccionada,ventaSeleccionada.id).subscribe((res) => {
                this.ventasProductoService.updateVentaProducto(this.EditVentaProductoForm.value,this.id).subscribe((res) => {
                  this.router.navigate(['/editarVentas/'+this.ventaProductoRef.idVenta])
                });
              });
          });

        });
    });
  }
}
