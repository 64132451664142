import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Material } from '@models/material.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private angulafirestore: AngularFirestore,
              public http: HttpClient) { }

  Materiales:Material[];
  material:any;

  getMateriales(id,tipo){

    let data = {
      id: id,
      tipo:tipo
    };
    return this.http.post<Material[]>('https://inclunova.cl/sgiCoquena/materialGet.php',data);
  }

  createMaterial(material:Material){
    let link= 'https://inclunova.cl/sgiCoquena/materialInsert.php';
    let data = {
      nombre:material.nombre,
      descripcion: material.descripcion,
      cantidad:  material.cantidad,
      valor: material.valor,
      tipo:material.tipo
    };
    return this.http.post(link, data);
  }

  updateMaterial(material:Material,id){
    let link= 'https://inclunova.cl/sgiCoquena/materialUpdate.php';
    let data = {
      id:id,
      nombre:material.nombre,
      descripcion: material.descripcion,
      cantidad:  material.cantidad,
      valor: material.valor
    };

    console.log(data);
    return this.http.post(link, data);
  }

  deleteMaterialLogic(id){
      let link= 'https://inclunova.cl/sgiCoquena/materialDelete.php';
      let data = {
          id:id
      };
      return this.http.post(link, data)
  }

  // //obtener
  // getMateriales(){
  //   return this.angulafirestore
  //   .collection("materiales", ref => ref.where('borrado', '!=', 1))
  //   .snapshotChanges()
  // }
  // getMaterialId(id){
  //   return this.angulafirestore
  //   .collection("materiales")
  //   .doc(id)
  //   .valueChanges()
  // }

  // createMaterial(material:Material){
  //   material.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   material.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   return new Promise<any> (( resolve, reject ) =>{
  //     this.angulafirestore
  //     .collection("materiales")
  //     .add(material)
  //     .then( (Response) => {
  //       console.log(Response)
  //     },
  //     (error) => {
  //       reject(error)
  //     })
  //   }) 
  // }

  // updateMaterial(material: Material, id){
  //   material.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
  //   return this.angulafirestore
  //   .collection("materiales")
  //   .doc(id)
  //   .update({
  //     nombre: material.nombre,
  //     descripcion: material.descripcion,
  //     valor: material.valor,
  //     cantidad: material.cantidad,
  //   })
  // }
  // deleteMaterialLogic(material: Material, id){
  //   return this.angulafirestore
  //   .collection("materiales")
  //   .doc(id)
  //   .update({
  //     borrado: material.borrado = 1
  //   })
  // }
  // deleteMaterial(material){
  //   return this.angulafirestore
  //   .collection("materiales")
  //   .doc(material.id)
  //   .delete()
  // }
}
