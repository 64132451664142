import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Deuda } from '@models/deuda.model';
import { DeudaService } from '@services/deuda.service';

@Component({
  selector: 'app-listardeudas',
  templateUrl: './listardeudas.component.html',
  styleUrls: ['./listardeudas.component.scss']
})
export class ListardeudasComponent {
  Deudas: Deuda[]

  constructor(private deudaService: DeudaService,
    private activatedRoute: ActivatedRoute){}
  
  ngOnInit(): void {
    this.deudaService.getDeudas(null).subscribe((data: Deuda[]) => {
      this.Deudas = data;
    });
  }

  deleteRow = (deuda) => {
    this.deudaService.deleteDeudaLogic(deuda.id).subscribe((res) => {
      this.deudaService.getDeudas(null).subscribe((data: Deuda[]) => {
        this.Deudas = data;
      });
    });
  } 
}
