<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Listado Ventas</h1>
                
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <button routerLink="/agregarVentas" class="btn btn-success" type="button">
                            <i class="fas fa-plus-circle"></i> Crear Venta
                        </button>
                    </li>
                    <li class="breadcrumb-item">
                        <button (click)="exportToExcel()" class="btn btn-success">Exportar a Excel</button>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<div class="content">
    <div class="container-fluid">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Medio Pago</th>
                    <th scope="col">Responsable</th>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Fecha Edición</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let venta of Ventas">
                    <td scope="row">  </td>
                    <td> {{venta.nombre}} </td>
                    <td> {{venta.descripcion}} </td>
                    <td> {{venta.valor}} </td>
                    <td> {{venta.medio_pago}} </td>
                    <td> {{venta.nombre_usuario}} </td>
                    <td> {{venta.fecha_creacion}} </td>
                    <td> {{venta.fecha_edicion}} </td>
                    <td>
                        <button routerLink="/editarVentas/{{venta.id}}" class="btn btn-outline-secondary"> <i class="fas fa-pen"></i></button>
                        <button (click)="deleteRow(venta)"class="btn btn-outline-danger"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>