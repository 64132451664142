import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Producto } from '@models/producto.model';
import { ProductoService } from '@services/producto.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent {
  Productos: Producto[]
  //Productos;
    constructor(private productoService: ProductoService){}
    
    ngOnInit(): void {
      this.productoService.getProductos(null).subscribe((data: Producto[]) => {
        this.Productos = data;
      });
    }
  
    deleteRow = (producto) => {
      this.productoService.deleteProductoLogic(producto.id).subscribe(() => {
        this.productoService.getProductos(null).subscribe((data: Producto[]) => {
          this.Productos = data;
        });
      });
    } 
}
