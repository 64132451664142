<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Crear Usuario</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="UsuarioForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Nombre:</label>
                <input type="text" formControlName="nombre" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Apellido Paterno:</label>
                <input type="text" formControlName="apellidop" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Apellido Materno:</label>
                <input type="text" formControlName="apellidom" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Rut:</label>
                <input type="text" formControlName="rut" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">DV:</label>
                <input type="text" formControlName="dv" type="text" class="form-control" required>
            </div>

            <div class="mb-3">
                <label class="form-label">Email:</label>
                <input type="text" formControlName="email" type="text" class="form-control" required>
            </div>

            <div class="mb-3">
                <label class="form-label">Paasword:</label>
                <input type="text" formControlName="password" type="text" class="form-control" required>
            </div>

            <button [disabled]="!UsuarioForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>
    </div>
</div>

