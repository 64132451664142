<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Listado Abonos</h1>
                
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <button routerLink="/agregarAbonos/{{id}}" class="btn btn-success" type="button">
                            <i class="fas fa-plus-circle"></i> Agregar Abono
                        </button>
                    </li>
                    <li class="breadcrumb-item">
                        <button routerLink="/editarVentas/{{id}}" class="btn btn-secondary" type="button">
                            <i class="fas fa-arrow-left"></i> Volver Venta
                        </button>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<div class="content">
    <div class="container-fluid">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Medio Pago</th>
                    <th scope="col">Responsable</th>
                    <th scope="col">Fecha Abono</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let abono of Abonos">
                    <td scope="row">  </td>
                    <td> {{abono.descripcion}} </td>
                    <td> {{abono.valor}} </td>
                    <td> {{abono.medio_pago}} </td>
                    <td> {{abono.nombre_usuario}} </td>
                    <td> {{abono.fecha_creacion}} </td>
                    <td>
                        <button (click)="deleteRow(abono)"class="btn btn-outline-danger"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>