import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Usuario } from '@models/usuario.model';
import { TareaService } from '@services/tarea.service';
import { UsuarioService } from '@services/usuario.service';

@Component({
  selector: 'app-agregartareas',
  templateUrl: './agregartareas.component.html',
  styleUrls: ['./agregartareas.component.scss']
})
export class AgregartareasComponent {
  public TareaForm: FormGroup;
  public Usuarios;

  constructor(
    public tareaService: TareaService,
    public formBuilder: FormBuilder,
    private router: Router,
    public usuariosService:UsuarioService
  ){ 
    this.TareaForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      usuario: [''],
      estado: ['En Proceso'],
      borrado: [0],
      fecha: [''],
    })
  }

  ngOnInit(): void {
    this.usuariosService.getUsuarios(null).subscribe((data: Usuario[]) => {
      this.Usuarios = data;
    });
  }

  onSubmit(){
    this.usuariosService.getUsuarios(this.TareaForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.TareaForm.value.nombre_usuario = usuario.nombre;
      this.tareaService.createTarea(this.TareaForm.value).subscribe((res) => {
        console.log(res);
        this.router.navigate(['/listarTareas'])
      })
    });
  }
}
