import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { formatDate } from '@angular/common';
import { Pago } from '@models/pago.model';
import { DeudaService } from './deuda.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagoService {
  deudaRef;
  Pagos;
  CollectionPagos;
  constructor(private http:HttpClient) { }


    getPagos(id,id_deuda){
      const data = {
        id: id,
        id_deuda:id_deuda
      };
      return this.http.post<Pago[]>('https://inclunova.cl/sgiCoquena/pagoGet.php',data);
    }
  
    createPago(pago:Pago){
      let link= 'https://inclunova.cl/sgiCoquena/pagoInsert.php';
      let data = {
        nombre:pago.nombre,
        descripcion: pago.descripcion,
        valor: pago.valor,
        usuario:pago.usuario,
        id_deuda:pago.id_deuda,
      };
      return this.http.post(link, data);
    }
  
    updatePago(pago:Pago,id){
      let link= 'https://inclunova.cl/sgiCoquena/pagoUpdate.php';
      let data = {
        id:id,
        nombre:pago.nombre,
        descripcion: pago.descripcion,
        valor: pago.valor
      };
      return this.http.post(link, data);
    }
  
    deletePagoLogic(id){
      let link= 'https://inclunova.cl/sgiCoquena/pagoDelete.php';
      let data = {
          id:id
      };
      return this.http.post(link, data)
    }

      // getPagos(id_deuda){
      //   return this.angulafirestore
      //   .collection("pagos", ref => ref.where('borrado', '!=', 1)
      //                                  .where('id_deuda','==',id_deuda)
      //                                  .orderBy('borrado'))            
      //   .snapshotChanges()
      // }
  
      // getPagosId(id){
      //   return this.angulafirestore
      //   .collection("pagos")
      //   .doc(id)
      //   .valueChanges()
      // }
    
      // createPago(pago:Pago){
      //   pago.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //   pago.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //   return new Promise<any> (( resolve, reject ) =>{
      //     this.angulafirestore
      //     .collection("pagos")
      //     .add(pago)
      //     .then( (Response) => {
      //       console.log(Response)
      //     },
      //     (error) => {
      //       reject(error)
      //     })
      //   }) 
      // }
    
      // updatePago(pago: Pago, id){
      //   pago.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //   return this.angulafirestore
      //   .collection("pagos")
      //   .doc(id)
      //   .update({
      //     nombre: pago.nombre,
      //     descripcion: pago.descripcion,
      //     valor: pago.valor,
      //   })
      // }
  
      // deletePagoLogic(pago: Pago, id){
      //   return this.angulafirestore
      //   .collection("pagos")
      //   .doc(id)
      //   .update({
      //     borrado: pago.borrado = 1
      //   })
      // }
  
      // deletePago(pago){
      //   return this.angulafirestore
      //   .collection("pagos")
      //   .doc(pago.id)
      //   .delete()
      // }
}
