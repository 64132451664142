import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from '@models/material.model';
import { MaterialService } from '@services/material.service';

@Component({
  selector: 'app-editarotros',
  templateUrl: './editarotros.component.html',
  styleUrls: ['./editarotros.component.scss']
})
export class EditarotrosComponent {
  public EditMaterialesForm: FormGroup;
  materialesRef: any

  constructor(
    public materialesService: MaterialService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
    this.EditMaterialesForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
      cantidad: ['']
    })

  }
  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.materialesService.getMateriales(id,null).subscribe((data: Material[]) => {
      this.materialesRef = data[0];
      this.EditMaterialesForm = this.formBuilder.group({
        nombre: [this.materialesRef.nombre],
        descripcion: [this.materialesRef.descripcion],
        valor: [this.materialesRef.valor],
        cantidad: [this.materialesRef.cantidad]
      })
    });
  }

  onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.materialesService.updateMaterial(this.EditMaterialesForm.value,id).subscribe((res) => {
      this.router.navigate(['/listarOtros'])
    });
  }
}
