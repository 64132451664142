import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '@services/usuario.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.scss']
})
export class AgregarComponent implements OnInit{
  UsuarioForm: FormGroup;
  constructor(
    public usuarioService: UsuarioService,
    public formBuilder: FormBuilder,
    private router: Router
  ){ 
    this.UsuarioForm = this.formBuilder.group({
      nombre: [''],
      apellidop: [''],
      apellidom: [''],
      rut: [''],
      dv: [''],
      borrado: [0],
      usuario: [1],
      fecha: [''],
      email:[''],
      password: ['']
    })
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.usuarioService.createUsuario(this.UsuarioForm.value).subscribe((res) => {
      console.log(res)
      this.router.navigate(['/listado'])
    });
  }
}
