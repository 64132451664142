import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from '@models/compra.model';
import { CompraService } from '@services/compra.service';

@Component({
  selector: 'app-modalimagencompra',
  templateUrl: './modalimagencompra.component.html',
  styleUrls: ['./modalimagencompra.component.scss']
})
export class ModalimagencompraComponent implements OnInit{

  id;
  comprasRef;

  constructor(
    public comprasService: CompraService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.comprasService.getCompras(this.id).subscribe((data: Compra[]) => {
      this.comprasRef = data[0];
    });
  }
}
