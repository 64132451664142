import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { formatDate } from '@angular/common';
import { UsuarioService } from './usuario.service';
import { Tarea } from '@models/tarea.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TareaService {
  usuarioRef:any;
  constructor(public http: HttpClient) { }

    getTareas(id){
      let data = {
        id: id
      };
      return this.http.post<Tarea[]>('https://inclunova.cl/sgiCoquena/tareasGet.php',data);
    }
  
    getTareasEstadistica(id){
      const data = {
        id: id,
      };
      return this.http.post<Tarea[]>('https://inclunova.cl/sgiCoquena/tareasGetEstadistica.php',data);
    }

    getTareasEstadisticaTop(id){
      const data = {
        id: id,
      };
      return this.http.post<Tarea[]>('https://inclunova.cl/sgiCoquena/tareasGetEstadisticaTOP.php',data);
    }

    createTarea(tarea:Tarea){
      let link= 'https://inclunova.cl/sgiCoquena/tareasInsert.php';
      let data = {
        nombre:tarea.nombre,
        nombre_usuario:tarea.nombre_usuario,
        usuario: tarea.usuario,
        estado:  tarea.estado,
        descripcion: tarea.descripcion,
      };
      return this.http.post(link, data);
    }
  
    updateTarea(tarea:Tarea,id){
      let link= 'https://inclunova.cl/sgiCoquena/tareasUpdate.php';
      let data = {
        id:id,
        nombre:tarea.nombre,
        nombre_usuario:tarea.nombre_usuario,
        usuario: tarea.usuario,
        estado:  tarea.estado,
        descripcion: tarea.descripcion,
      };
  
      console.log(data);
      return this.http.post(link, data);
    }
  
    deletelTareaLogic(id){
        let link= 'https://inclunova.cl/sgiCoquena/tareasDelete.php';
        let data = {
            id:id
        };
        return this.http.post(link, data)
    }

}
