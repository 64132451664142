<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Crear Otro</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="MaterialForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Nombre:</label>
                <input type="text" formControlName="nombre" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Descripcion:</label>
                <input type="text" formControlName="descripcion" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Valor:</label>
                <input type="text" formControlName="valor" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Cantidad:</label>
                <input type="text" formControlName="cantidad" type="text" class="form-control" required>
            </div>
            <button [disabled]="!MaterialForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>
    </div>
</div>

