import { Component } from '@angular/core';
import { ProductoService } from '@services/producto.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agregar-productos',
  templateUrl: './agregar-productos.component.html',
  styleUrls: ['./agregar-productos.component.scss']
})
export class AgregarProductosComponent {
  ProductoForm: FormGroup;
  constructor(
    public productoService: ProductoService,
    public formBuilder: FormBuilder,
    private router: Router
  ){ 
    this.ProductoForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      cantidad: [0],
      valor: [0],
      borrado: [0],
      fecha: [''],
    })
  }

  ngOnInit(): void {
  }
  
  onSubmit(){
    this.productoService.createProducto(this.ProductoForm.value).subscribe((res) => {
      this.router.navigate(['/listarProductos'])
    });
  }
}
