import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { formatDate } from '@angular/common';
import { Deuda } from '@models/deuda.model';

import { of, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeudaService {
  
  constructor(public http: HttpClient) { }

  getDeudas(id){
    const data = {
      id: id,
    };
    return this.http.post<Deuda[]>('https://inclunova.cl/sgiCoquena/deudaGet.php',data);
  }


  getDeudaEstadistica(id){
    const data = {
      id: id,
    };
    return this.http.post<Deuda[]>('https://inclunova.cl/sgiCoquena/deudaGetEstadistica.php',data);
  }

  createDeuda(deuda:Deuda){
    let link= 'https://inclunova.cl/sgiCoquena/deudaInsert.php';
    let data = {
      nombre:deuda.nombre,
      descripcion: deuda.descripcion,
      valor: deuda.valor
    };
    return this.http.post(link, data);
  }

  updateDeuda(deuda:Deuda,id){
    let link= 'https://inclunova.cl/sgiCoquena/deudaUpdate.php';
    let data = {
      id:id,
      nombre:deuda.nombre,
      descripcion: deuda.descripcion,
      valor: deuda.valor,
      valor_pagado:deuda.valor_pagado,
    };
    return this.http.post(link, data);
  }

  deleteDeudaLogic(id){
    let link= 'https://inclunova.cl/sgiCoquena/deudaDelete.php';
    let data = {
        id:id
    };
    return this.http.post(link, data)
  }

    // //obtener
    // getDeudas(){
    //   return this.angulafirestore
    //   .collection("deudas", ref => ref.where('borrado', '!=', 1))
    //   .snapshotChanges()
    // }

    // getDeudaId(id){
    //   return this.angulafirestore
    //   .collection("deudas")
    //   .doc(id)
    //   .valueChanges()
    // }
  
    // createDeuda(deuda:Deuda){
    //   deuda.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   deuda.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   return new Promise<any> (( resolve, reject ) =>{
    //     this.angulafirestore
    //     .collection("deudas")
    //     .add(deuda)
    //     .then( (Response) => {
    //       console.log(Response)
    //     },
    //     (error) => {
    //       reject(error)
    //     })
    //   }) 
    // }
  
    // updateDeuda(deuda: Deuda, id){
    //   deuda.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   return  this.angulafirestore
    //   .collection("deudas")
    //   .doc(id)
    //   .update({
    //     nombre: deuda.nombre,
    //     descripcion: deuda.descripcion,
    //     valor: deuda.valor,
    //     valor_pagado: deuda.valor_pagado,
    //   })
    // }

    // deleteDeudaLogic(deuda: Deuda, id){
    //   return this.angulafirestore
    //   .collection("deudas")
    //   .doc(id)
    //   .update({
    //     borrado: deuda.borrado = 1
    //   })
    // }

    // deleteDeuda(producto){
    //   return this.angulafirestore
    //   .collection("deudas")
    //   .doc(producto.id)
    //   .delete()
    // }
}
