import { Injectable } from '@angular/core';
import { Producto } from '../models/producto.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(private angulafirestore: AngularFirestore,
              public http: HttpClient) { }

    Productos:Producto[];
    producto:any;

    getProductos(id){

      const data = {
        id: id,
      };
      return this.http.post<Producto[]>('https://inclunova.cl/sgiCoquena/productoGet.php',data);
    }

    getProductosEstadistica(id){

      const data = {
        id: id,
      };
      return this.http.post<Producto[]>('https://inclunova.cl/sgiCoquena/productoGetEstadisticaTOP.php',data);
    }

    createProducto(producto:Producto){
      let link= 'https://inclunova.cl/sgiCoquena/productoInsert.php';
      let data = {
        nombre:producto.nombre,
        descripcion: producto.descripcion,
        cantidad:  producto.cantidad,
        valor: producto.valor
      };
      return this.http.post(link, data);
    }

    updateProducto(producto:Producto,id){
      let link= 'https://inclunova.cl/sgiCoquena/productoUpdate.php';
      let data = {
        id:id,
        nombre:producto.nombre,
        descripcion: producto.descripcion,
        cantidad:  producto.cantidad,
        valor: producto.valor
      };
      return this.http.post(link, data);
    }

    deleteProductoLogic(id){
        let link= 'https://inclunova.cl/sgiCoquena/productoDelete.php';
        let data = {
            id:id
        };
        return this.http.post(link, data)
    }

    //obtener
    // getProductos(){
    //   return this.angulafirestore
    //   .collection("productos", ref => ref.where('borrado', '!=', 1))
    //   .snapshotChanges()
    // }
    
    // getProductoId(id){
    //   return this.angulafirestore
    //   .collection("productos")
    //   .doc(id)
    //   .valueChanges()
    // }
  
    // createProducto(producto:Producto){
    //   producto.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   producto.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   return new Promise<any> (( resolve, reject ) =>{
    //     this.angulafirestore
    //     .collection("productos")
    //     .add(producto)
    //     .then( (Response) => {
    //       console.log(Response)
    //     },
    //     (error) => {
    //       reject(error)
    //     })
    //   }) 
    // }
  
    // updateProducto(producto: Producto, id){
    //   producto.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   return this.angulafirestore
    //   .collection("productos")
    //   .doc(id)
    //   .update({
    //     nombre: producto.nombre,
    //     descripcion: producto.descripcion,
    //     valor: producto.valor,
    //     cantidad: producto.cantidad,
    //   })
    // }

    // updateProductoCantidad(cantidad, id){

    //   this.producto = this.angulafirestore
    //   .collection("productos")
    //   .doc(id)
    //   .valueChanges();

    //   let cantidad_final = this.producto.cantidad;
    //   let tempValorRes =  cantidad.toString();
  
    //   cantidad_final= cantidad_final  - parseInt(tempValorRes);

    //   let fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
    //   return this.angulafirestore
    //   .collection("productos")
    //   .doc(id)
    //   .update({
    //     cantidad: cantidad,
    //     fecha_edicion:fecha_edicion
    //   })
    // }

    
    // deleteProductoLogic(producto: Producto, id){
    //   return this.angulafirestore
    //   .collection("productos")
    //   .doc(id)
    //   .update({
    //     borrado: producto.borrado = 1
    //   })
    // }

    // deleteProducto(producto){
    //   return this.angulafirestore
    //   .collection("productos")
    //   .doc(producto.id)
    //   .delete()
    // }
}
