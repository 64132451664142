import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Pago } from '@models/pago.model';
import { PagoService } from '@services/pago.service';
import { DeudaService } from '@services/deuda.service';

@Component({
  selector: 'app-editardeudas',
  templateUrl: './editardeudas.component.html',
  styleUrls: ['./editardeudas.component.scss']
})
export class EditardeudasComponent {
  public EditDeudaForm: FormGroup;
  deudaRef: any;
  Pagos: Pago[]
  id;

  constructor(
    public deudaService: DeudaService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public pagoService: PagoService
  ){
    this.EditDeudaForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
      valor_pagado: [''],
    })
    this.id = this.activatedRoute.snapshot.paramMap.get('id');

  }
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.pagoService.getPagos(null,this.id).subscribe((data: Pago[]) => {
      this.Pagos = data;
    });

    this.deudaService.getDeudas(this.id).subscribe( res => {
      this.deudaRef = res[0]
      this.EditDeudaForm = this.formBuilder.group({
        nombre: [this.deudaRef.nombre],
        descripcion: [this.deudaRef.descripcion],
        valor: [this.deudaRef.valor],
        valor_pagado: [this.deudaRef.valor_pagado]
      })
    })
  }

 onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.deudaService.updateDeuda(this.EditDeudaForm.value,id).subscribe((res) => {
      this.router.navigate(['/listarDeudas'])
    });
  }

    deleteRow = (pago) => {
      var tempValor = pago.valor.toString();
      var valor_pagado_deuda = this.EditDeudaForm.value.valor_pagado.toString();
      this.EditDeudaForm.value.valor_pagado= parseInt(valor_pagado_deuda) - parseInt(tempValor);
      this.deudaService.updateDeuda(this.EditDeudaForm.value,this.id).subscribe((res) => {
        this.EditDeudaForm = this.formBuilder.group({
          nombre: [this.EditDeudaForm.value.nombre],
          descripcion: [this.EditDeudaForm.value.descripcion],
          valor: [this.EditDeudaForm.value.valor],
          valor_pagado: [this.EditDeudaForm.value.valor_pagado]
        })
        this.pagoService.deletePagoLogic(pago.id).subscribe((res) => {
          this.pagoService.getPagos(null,this.id).subscribe((data: Pago[]) => {
            this.Pagos = data;
          });
        });
      });
    } 
}
