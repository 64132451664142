import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Compra } from '@models/compra.model';
import { Compramaterial } from '@models/compramaterial.model';
import { Material } from '@models/material.model';
import { CompraService } from '@services/compra.service';
import { CompramaterialService } from '@services/compramaterial.service';
import { MaterialService } from '@services/material.service';

@Component({
  selector: 'app-editarcomprasmateriales',
  templateUrl: './editarcomprasmateriales.component.html',
  styleUrls: ['./editarcomprasmateriales.component.scss']
})
export class EditarcomprasmaterialesComponent {
  public EditCompraMaterialForm: FormGroup;
  compraMaterialRef: any;
  id;
  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public compraMaterialService: CompramaterialService,
    public materialService: MaterialService,
    public compraService: CompraService
  ){
    this.EditCompraMaterialForm = this.formBuilder.group({
      cantidad: [''],
      valor: [''],
    })
  }


  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.compraMaterialService.getComprasMateriales(null,this.id).subscribe((data: Compramaterial[]) => {
      this.compraMaterialRef = data[0];
      this.EditCompraMaterialForm = this.formBuilder.group({
        cantidad: [this.compraMaterialRef.cantidad],
        valor: [this.compraMaterialRef.valor],
      })
    });
  }

 onSubmit(){
    this.materialService.getMateriales(this.EditCompraMaterialForm.value.idMaterial,null).subscribe((data: Material[]) => {
      let materialSeleccionado = data[0];

      var cant =  materialSeleccionado.cantidad.toString();
      var cantTemp;
      var cantFinal;
      var tempValorRes =  this.EditCompraMaterialForm.value.cantidad;
      var tempValor = this.compraMaterialRef.cantidad.toString();
      //devuelve al valor original
      cantFinal = parseInt(cant) + parseInt(tempValorRes);
      cantTemp = parseInt(cantFinal) - parseInt(tempValor);
      materialSeleccionado.cantidad = cantTemp;
      this.materialService.updateMaterial(materialSeleccionado,materialSeleccionado.id).subscribe((res) => {
          // this.ventasProductoService.updateVentaProducto(this.EditVentaProductoForm.value,this.id).subscribe((res) => {
          //   this.router.navigate(['/editarVentas/'+this.ventaProductoRef.idVenta])
          // });

          this.compraService.getCompras(this.EditCompraMaterialForm.value.idCompra).subscribe((data: Compra[]) => {
            let compraSeleccionada = data[0];
      
            var valor =  compraSeleccionada.valor.toString();
            var valorTempo;
            var valorFinal;
            var tempoValor =  this.EditCompraMaterialForm.value.valor;
            var tempoValorRes = this.compraMaterialRef.valor.toString();
            //devuelve al valor original
            valorTempo = parseInt(valor) - parseInt(tempoValorRes);
            valorFinal = parseInt(valorTempo) + parseInt(tempoValor);
            compraSeleccionada.valor = valorFinal;
            this.compraService.updateCompra(compraSeleccionada,compraSeleccionada.id).subscribe((res) => {
                this.compraMaterialService.updateCompraMaterial(this.EditCompraMaterialForm.value,this.id).subscribe((res) => {
                  this.router.navigate(['/editarCompras/'+this.compraMaterialRef.idCompra])
                });
              });
          });

        });
    });
  }
}
