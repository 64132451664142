import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { ProductosComponent } from '@pages/productos/listarProductos/productos.component';
import { AgregarComponent } from '@pages/usuarios/agregar/agregar.component';
import { EditarComponent } from '@pages/usuarios/editar/editar.component';
import { ListadoComponent } from '@pages/usuarios/listado/listado.component';
import { AgregarProductosComponent } from '@pages/productos/agregar-productos/agregar-productos.component';
import { EditarproductosComponent } from '@pages/productos/editarproductos/editarproductos.component';
import { ListarventasComponent } from '@pages/ventas/listarventas/listarventas.component';
import { ListarcomprasComponent } from '@pages/compras/listarcompras/listarcompras.component';
import { AgregarcomprasComponent } from '@pages/compras/agregarcompras/agregarcompras.component';
import { AgregarventasComponent } from '@pages/ventas/agregarventas/agregarventas.component';
import { EditarcomprasComponent } from '@pages/compras/editarcompras/editarcompras.component';
import { EditarventasComponent } from '@pages/ventas/editarventas/editarventas.component';
import { ListarmaterialComponent } from '@pages/material/listarmaterial/listarmaterial.component';
import { AgregarmaterialComponent } from '@pages/material/agregarmaterial/agregarmaterial.component';
import { EditarmaterialComponent } from '@pages/material/editarmaterial/editarmaterial.component';
import { ListartareasComponent } from '@pages/tareas/listartareas/listartareas.component';
import { AgregartareasComponent } from '@pages/tareas/agregartareas/agregartareas.component';
import { EditartareasComponent } from '@pages/tareas/editartareas/editartareas.component';
import { AgregarpagosComponent } from '@pages/pagos/agregarpagos/agregarpagos.component';
import { EditarpagosComponent } from '@pages/pagos/editarpagos/editarpagos.component';
import { ListardeudasComponent } from '@pages/deudas/listardeudas/listardeudas.component';
import { AgregardeudasComponent } from '@pages/deudas/agregardeudas/agregardeudas.component';
import { EditardeudasComponent } from '@pages/deudas/editardeudas/editardeudas.component';
import { AgregarventasproductosComponent } from '@pages/ventas/agregarventasproductos/agregarventasproductos.component';
import { EditarventasproductosComponent } from '@pages/ventas/editarventasproductos/editarventasproductos.component';
import { AgregarcomprasmaterialesComponent } from '@pages/compras/agregarcomprasmateriales/agregarcomprasmateriales.component';
import { EditarcomprasmaterialesComponent } from '@pages/compras/editarcomprasmateriales/editarcomprasmateriales.component';
import { AgregarotrosComponent } from '@pages/material/agregarotros/agregarotros.component';
import { ListarotrosComponent } from '@pages/material/listarotros/listarotros.component';
import { EditarotrosComponent } from '@pages/material/editarotros/editarotros.component';
import { AgregarcomprasotrosComponent } from '@pages/compras/agregarcomprasotros/agregarcomprasotros.component';
import { ModalimagenComponent } from '@pages/modals/modalimagen/modalimagen.component';
import { ModalimagencompraComponent } from '@pages/modals/modalimagencompra/modalimagencompra.component';
import { ListarabonosComponent } from '@pages/abono/listarabonos/listarabonos.component';
import { AgregarabonoComponent } from '@pages/abono/agregarabono/agregarabono.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'agregar',
                component: AgregarComponent
            },
            {
                path: 'editar/:id',
                component: EditarComponent
            },
            {
                path: 'listado',
                component: ListadoComponent
            },
            {
                path: 'listarProductos',
                component: ProductosComponent
            },
            {
                path: 'agregarProductos',
                component: AgregarProductosComponent
            },
            {
                path: 'editarProductos/:id',
                component: EditarproductosComponent
            },
            {
                path: 'listarMateriales',
                component: ListarmaterialComponent
            },
            {
                path: 'agregarOtros',
                component: AgregarotrosComponent
            },
            {
                path: 'listarOtros',
                component: ListarotrosComponent
            },
            {
                path: 'agregarMateriales',
                component: AgregarmaterialComponent
            },
            {
                path: 'editarMateriales/:id',
                component: EditarmaterialComponent
            },
            {
                path: 'editarOtros/:id',
                component: EditarotrosComponent
            },
            {
                path: 'listarVentas',
                component: ListarventasComponent
            },            
            {
                path: 'agregarVentas',
                component: AgregarventasComponent
            },            
            {
                path: 'editarVentas/:id',
                component: EditarventasComponent
            },
            {
                path: 'listarCompras',
                component: ListarcomprasComponent
            },
            {
                path: 'agregarCompras',
                component: AgregarcomprasComponent
            },
            {
                path: 'editarCompras/:id',
                component: EditarcomprasComponent
            },
            {
                path: 'listarTareas',
                component: ListartareasComponent
            },
            {
                path: 'agregarTareas',
                component: AgregartareasComponent
            },
            {
                path: 'editarTareas/:id',
                component: EditartareasComponent
            },
            {
                path: 'listarDeudas',
                component: ListardeudasComponent
            },
            {
                path: 'agregarDeudas',
                component: AgregardeudasComponent
            },
            {
                path: 'editarDeudas/:id',
                component: EditardeudasComponent
            },
            {
                path: 'agregarPagos/:id',
                component: AgregarpagosComponent
            },
            {
                path: 'editarPagos/:id',
                component: EditarpagosComponent
            },
            {
                path: 'agregarVentaProducto/:id',
                component: AgregarventasproductosComponent
            },
            {
                path: 'editarVentaProducto/:id',
                component: EditarventasproductosComponent
            },
            {
                path: 'agregarCompraMaterial/:id',
                component: AgregarcomprasmaterialesComponent
            },
            {
                path: 'agregarCompraOtro/:id',
                component: AgregarcomprasotrosComponent
            },
            {
                path: 'editarCompraMaterial/:id',
                component: EditarcomprasmaterialesComponent
            },
            {
                path: 'editarCompraMaterial/:id',
                component: EditarcomprasmaterialesComponent
            },
            {
                path: 'imagenVenta/:id',
                component: ModalimagenComponent
            },
            {
                path: 'imagenCompra/:id',
                component: ModalimagencompraComponent
            },
            {
                path: 'listarAbonos/:id',
                component: ListarabonosComponent
            },
            {
                path: 'agregarAbonos/:id',
                component: AgregarabonoComponent
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
