import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    {
        name: 'Usuarios',
        iconClasses: 'fas fa-users',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-address-book',
                path: ['/listado']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-user-plus',
                path: ['/agregar']
            }
        ]
    },
    {
        name: 'Productos',
        iconClasses: 'fas fa-wine-bottle',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarProductos']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarProductos']
            },
        ]
    },
    {
        name: 'Insumos',
        iconClasses: 'fas fa-truck-loading',      
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarMateriales']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarMateriales']
            },
        ]
    },
    {
        name: 'Otros',
        iconClasses: 'fas fa-shapes',      
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarOtros']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarOtros']
            },
        ]
    },
    {
        name: 'Ventas',
        iconClasses: 'fas fa-cash-register',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarVentas']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarVentas']
            },
        ]
    },

    {
        name: 'Gastos',
        iconClasses: 'fas fa-shopping-cart',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarCompras']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarCompras']
            },
        ]
    },

    {
        name: 'Tareas',
        iconClasses: 'fas fa-tasks',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarTareas']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarTareas']
            },
        ]
    },
    {
        name: 'Compromisos Economicos',
        iconClasses: 'fas fa-suitcase',        
        children: [
            
            {
                name: 'Listado',
                iconClasses: 'fas fa-list-alt',
                path: ['listarDeudas']
            },
            {
                name: 'Agregar',
                iconClasses: 'far fa-plus-circle',
                path: ['/agregarDeudas']
            },
        ]
    }
];
