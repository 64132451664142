import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '@services/usuario.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { Usuario } from '@models/usuario.model';
@Component({
  selector: 'app-editar',
  templateUrl: './editar.component.html',
  styleUrls: ['./editar.component.scss']
})
export class EditarComponent implements OnInit{
  public EditUsuarioForm: FormGroup;
  usuarioRef: any

  constructor(
    public usuarioService: UsuarioService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){
    this.EditUsuarioForm = this.formBuilder.group({
      nombre: [''],
      apellidop: [''],
      apellidom: [''],
      rut: [''],
      dv: [''],
      email: [''],
      password: [''],
    })

  }
  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.usuarioService.getUsuarios(id).subscribe((data: Usuario[]) => {
      this.usuarioRef = data[0];
      this.EditUsuarioForm = this.formBuilder.group({
        nombre: [this.usuarioRef.nombre],
        apellidop: [this.usuarioRef.apellidop],
        apellidom: [this.usuarioRef.apellidom],
        rut: [this.usuarioRef.rut],
        dv: [this.usuarioRef.dv],
        email: [this.usuarioRef.email],
        password: [this.usuarioRef.password]
      })
    });
  }

  onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.usuarioService.updateUsuario(this.EditUsuarioForm.value,id).subscribe((res) => {
      console.log(res);
      this.router.navigate(['/listado'])
    });
  }
}
