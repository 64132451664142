<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Imagen Venta</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
            <div class="mb-3">
                <div class="card card-widget">
                    <div class="card-header">

                        <!-- <div class="user-block">
                            <img class="img-circle" src="../dist/img/user1-128x128.jpg" alt="User Image">
                            <span class="username"><a href="#">Jonathan Burke Jr.</a></span>
                            <span class="description">Shared publicly - 7:30 PM Today</span>
                        </div> -->
                        
                        <!-- <div class="card-tools">
                        <button type="button" class="btn btn-tool" title="Mark as read">
                        <i class="far fa-circle"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                        <i class="fas fa-times"></i>
                        </button>
                        </div> -->
                    
                    </div>
                    
                    <div class="card-body">
                        <img class="img-fluid pad" src="http://www.inclunova.cl/sgiCoquena/docs/{{ventasRef.imagen}}" alt="Photo">
                        <p></p>
                    </div>
                    
                    <div class="card-footer">
                    </div>
                    
                </div>
            </div>

            <div class="mb-3">
                <div class="input-group-append">
                    <a class="input-group-text" routerLink="/editarVentas/{{ventasRef.id}}">Volver Venta</a>
                </div>
            </div>
    </div>
</div>

