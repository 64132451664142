import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Venta } from '@models/venta.model';
import { VentaService } from '@services/venta.service';

@Component({
  selector: 'app-modalimagen',
  templateUrl: './modalimagen.component.html',
  styleUrls: ['./modalimagen.component.scss']
})
export class ModalimagenComponent implements OnInit{

  id;
  ventasRef;

  constructor(
    public ventasService: VentaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.ventasService.getVentas(this.id).subscribe((data: Venta[]) => {
      this.ventasRef = data[0];
    });
  }
}
