import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tarea } from '@models/tarea.model';
import { TareaService } from '@services/tarea.service';

@Component({
  selector: 'app-listartareas',
  templateUrl: './listartareas.component.html',
  styleUrls: ['./listartareas.component.scss']
})
export class ListartareasComponent {
  Tareas: Tarea[]
  
  constructor(private tareaService: TareaService,
    private activatedRoute: ActivatedRoute){}
  
  ngOnInit(): void {
    this.tareaService.getTareas(null).subscribe((data: Tarea[]) => {
      this.Tareas = data;
    });
  }


  deleteRow = (tarea) => {
    this.tareaService.deletelTareaLogic(tarea.id).subscribe(() => {
      this.tareaService.getTareas(null).subscribe((data: Tarea[]) => {
        this.Tareas = data;
      });
    });
  } 
}
