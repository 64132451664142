import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(public http: HttpClient) { }

  public uploadImage(imageSrc) {
    return this.http.post('https://inclunova.cl/sgiCoquena/upload.php',imageSrc);
  }
}
