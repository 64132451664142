import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Material } from '@models/material.model';
import { MaterialService } from '@services/material.service';

@Component({
  selector: 'app-listarotros',
  templateUrl: './listarotros.component.html',
  styleUrls: ['./listarotros.component.scss']
})
export class ListarotrosComponent {
  Materiales: Material[]
    constructor(private materialService: MaterialService,
      private activatedRoute: ActivatedRoute){}
    
    ngOnInit(): void {
      this.materialService.getMateriales(null,2).subscribe((data: Material[]) => {
        this.Materiales = data;
      });
    }
  
    deleteRow = (material) => {
      this.materialService.deleteMaterialLogic(material.id).subscribe(() => {
        this.materialService.getMateriales(null,2).subscribe((data: Material[]) => {
          this.Materiales = data;
        });
      });
    } 
}
