import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Producto } from '@models/producto.model';
import { Venta } from '@models/venta.model';
import { ProductoService } from './producto.service';

@Injectable({
  providedIn: 'root'
})
export class VentaService {
  productoRef: any
  constructor(public http: HttpClient) { }


  getVentas(id){
    const data = {
      id: id,
    };
    return this.http.post<Venta[]>('https://inclunova.cl/sgiCoquena/ventaGet.php',data);
  }


  getVentasEstadistica(id){
    const data = {
      id: id,
    };
    return this.http.post<Venta[]>('https://inclunova.cl/sgiCoquena/ventaGetEstadistica.php',data);
  }

  createVenta(venta:Venta,imagen){
    let link= 'https://inclunova.cl/sgiCoquena/ventaInsert.php';
    let data = {
      nombre:venta.nombre,
      descripcion: venta.descripcion,
      valor: venta.valor,
      usuario:venta.usuario,
      nombre_usuario:venta.nombre_usuario,
      medio_pago:venta.medio_pago,
      imagen:imagen,
      fecha_edicion:venta.fecha_edicion
    };
    console.log(data);
    return this.http.post(link, data);
  }

  updateVenta(venta:Venta,id){
    let link= 'https://inclunova.cl/sgiCoquena/ventaUpdate.php';
    let data = {
      id:id,
      nombre:venta.nombre,
      descripcion: venta.descripcion,
      valor: venta.valor,
      usuario:venta.usuario,
      nombre_usuario:venta.nombre_usuario,
      medio_pago:venta.medio_pago,
      imagen:venta.imagen,
      fecha_edicion:venta.fecha_edicion,
      pago_cheque:venta.pago_cheque,
    };
    console.log(data);
    return this.http.post(link, data);
  }

  deleteVentaLogic(id){
    let link= 'https://inclunova.cl/sgiCoquena/ventaDelete.php';
    let data = {
        id:id
    };
    return this.http.post(link, data)
  }

      // //obtener
      // getVentas(){
      //   return this.angulafirestore
      //   .collection("ventas", ref => ref.where('borrado', '!=', 1))
      //   .snapshotChanges()
      // }

      // getVentaId(id){
      //   return this.angulafirestore
      //   .collection("ventas")
      //   .doc(id)
      //   .valueChanges()
      // }

      // createVenta(venta:Venta, productos){

      //   productos.forEach(producto => {
      //       if(producto.id == venta.producto){
      //         this.productoRef = producto
      //       }
      //   })

      //   venta.fecha_creacion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //   venta.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');
      //   venta.nombre_producto =  this.productoRef.nombre;

      //   return new Promise<any> (( resolve, reject ) =>{
      //     this.angulafirestore
      //     .collection("ventas")
      //     .add(venta)
      //     .then( (Response) => {
      //       console.log(Response)
      //     },
      //     (error) => {
      //       reject(error)
      //     })
      //   }) 
      // }
    
      // updateVenta(venta: Venta, id){
      //   this.productoService.getProductoId(venta.producto).subscribe( res => {
      //     this.productoRef = res
      //     venta.fecha_edicion = formatDate(new Date(), 'dd/MM/yyyy hh:mm:ss', 'en');;
      //     venta.nombre_producto =  this.productoRef.nombre;
      //     return this.angulafirestore
      //     .collection("ventas")
      //     .doc(id)
      //     .update({
      //       nombre: venta.nombre,
      //       descripcion: venta.descripcion,
      //       valor: venta.valor,
      //       cantidad: venta.cantidad,
      //       producto: venta.producto,
      //       nombre_producto: venta.nombre_producto,
      //       fecha_edicion: venta.fecha_edicion
      //     })
      //   })
      // }

      // deleteVentaLogic(venta: Venta, id){
      //   return this.angulafirestore
      //   .collection("ventas")
      //   .doc(id)
      //   .update({
      //     borrado: venta.borrado = 1
      //   })
      // }

      // deleteVenta(venta){
      //   return this.angulafirestore
      //   .collection("ventas")
      //   .doc(venta.id)
      //   .delete()
      // }
}
