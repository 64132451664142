<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Listado Tareas</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <button routerLink="/agregarTareas" class="btn btn-success" type="button">
                            <i class="fas fa-plus-circle"></i> Crear Tarea
                        </button>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>


<div class="content">
    <div class="container-fluid">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Descripción</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Fecha Edición</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let tarea of Tareas">
                    <td scope="row">  </td>
                    <td> {{tarea.nombre}} </td>
                    <td> {{tarea.descripcion}} </td>
                    <td> {{tarea.nombre_usuario}} </td>
                    <td> {{tarea.estado}} </td>
                    <td> {{tarea.fecha_creacion}} </td>
                    <td> {{tarea.fecha_edicion}} </td>
                    <td>
                        <button routerLink="/editarTareas/{{tarea.id}}" class="btn btn-outline-secondary"> <i class="fas fa-pen"></i></button>
                        <button (click)="deleteRow(tarea)"class="btn btn-outline-danger"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>