import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeudaService } from '@services/deuda.service';
import { PagoService } from '@services/pago.service';


@Component({
  selector: 'app-agregarpagos',
  templateUrl: './agregarpagos.component.html',
  styleUrls: ['./agregarpagos.component.scss']
})
export class AgregarpagosComponent {
  public PagoForm: FormGroup;
  deudaRef=null;
  constructor(
    public pagoService: PagoService,
    public deudaService: DeudaService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){ 
    this.PagoForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      id_deuda: [''],
      valor: [0],
      borrado: [0],
      fecha: [''],
    })
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.deudaService.getDeudas(id).subscribe( res => {
      this.deudaRef = res[0];
    })
  }

  onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.PagoForm.value.id_deuda = id;

    /*ACTUALIZA DEUDA*/
    var tempValor = this.PagoForm.value.valor.toString();
    var valor_pagado_deuda = this.deudaRef.valor_pagado.toString();
    this.deudaRef.valor_pagado= parseInt(valor_pagado_deuda)  + parseInt(tempValor);
    this.deudaService.updateDeuda(this.deudaRef,id).subscribe((res) => {
      this.pagoService.createPago(this.PagoForm.value).subscribe((resPago) => {
        this.router.navigate(['/editarDeudas/' + this.PagoForm.value.id_deuda])
      })
    });
    /*ACTUALIZA DEUDA*/
  }
}
