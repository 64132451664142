import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@models/usuario.model';
import { UsuarioService } from '@services/usuario.service';
import { TareaService } from '@services/tarea.service';
import { Tarea } from '@models/tarea.model';

@Component({
  selector: 'app-editartareas',
  templateUrl: './editartareas.component.html',
  styleUrls: ['./editartareas.component.scss']
})
export class EditartareasComponent {
  public EditTareaForm: FormGroup;
  tareasRef: any;
  public Usuarios;

  constructor(
    public tareaService: TareaService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public usuarioService: UsuarioService
  ){
    this.EditTareaForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      estado: [''],
      usuario: ['']
    })

  }
  ngOnInit(): void {
    this.usuarioService.getUsuarios(null).subscribe((data: Usuario[]) => {
      this.Usuarios = data;
    });

    const id = this.activatedRoute.snapshot.paramMap.get('id')
    this.tareaService.getTareas(id).subscribe((data: Tarea[]) => {
      this.tareasRef = data[0];
      this.EditTareaForm = this.formBuilder.group({
        nombre: [this.tareasRef.nombre],
        descripcion: [this.tareasRef.descripcion],
        estado: [this.tareasRef.estado],
        usuario: [this.tareasRef.usuario]
      })
    });
  }

 onSubmit(){
    const id = this.activatedRoute.snapshot.paramMap.get('id')

    this.usuarioService.getUsuarios(this.EditTareaForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.EditTareaForm.value.nombre_usuario = usuario.nombre;
      this.tareaService.updateTarea(this.EditTareaForm.value,id).subscribe((res) => {
        console.log(res);
        this.router.navigate(['/listarTareas'])
      });
    });
  }
}
