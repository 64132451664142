import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Producto } from '@models/producto.model';
import { Usuario } from '@models/usuario.model';
import { Venta } from '@models/venta.model';
import { Ventaproducto } from '@models/ventaproducto.model';
import { ImageService } from '@services/image.service';
import { ProductoService } from '@services/producto.service';
import { UsuarioService } from '@services/usuario.service';
import { VentaService } from '@services/venta.service';
import { VentaproductoService } from '@services/ventaproducto.service';

import Inputmask from 'inputmask';

@Component({
  selector: 'app-editarventas',
  templateUrl: './editarventas.component.html',
  styleUrls: ['./editarventas.component.scss']
})
export class EditarventasComponent {
  public EditVentasForm: FormGroup;
  ventasRef: any;
  public ventasProductos;
  id;
  public Usuarios;
  muestraCheque=0;
  medioPago:any='';
  file_data:any='';

  @ViewChild('myInput') myInputElementRef: ElementRef;

  constructor(
    public ventasService: VentaService,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public ventasProductoService: VentaproductoService,
    public productoService: ProductoService,
    public usuariosService:UsuarioService,
    public imageService:ImageService
  ){
    this.EditVentasForm = this.formBuilder.group({
      nombre: [''],
      descripcion: [''],
      valor: [''],
      usuario: [0],
      nombre_usuario: [''],
      medio_pago: [''],
      imagen: [''],
      fecha_edicion:[''],
      pago_cheque:['En Proceso'],
    })
  }

  ngAfterViewInit(): void {
    Inputmask('datetime', {
    inputFormat: 'yyyy/mm/dd',
    placeholder: 'yyyy/mm/dd',
    alias: 'datetime',
    min: '2020/01/01',
    clearMaskOnLostFocus: false,
    isComplete: function(buffer, opts) {
    }
  }).mask(this.myInputElementRef.nativeElement);
}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id')
    this.ventasService.getVentas(this.id).subscribe((data: Venta[]) => {
      this.ventasRef = data[0];
      if(this.ventasRef.medio_pago == 'Cheque'){
        this.muestraCheque = 1;
      }

      this.medioPago = this.ventasRef.medio_pago;

      this.EditVentasForm = this.formBuilder.group({
        nombre: [this.ventasRef.nombre],
        descripcion: [this.ventasRef.descripcion],
        valor: [this.ventasRef.valor],
        usuario: [this.ventasRef.usuario],
        medio_pago: [this.ventasRef.medio_pago],
        imagen: [this.ventasRef.imagen],
        fecha_edicion: [this.ventasRef.fecha_edicion],
        pago_cheque: [this.ventasRef.pago_cheque],
        deuda: [this.ventasRef.deuda],
        abono: [this.ventasRef.abono],
      })

      this.usuariosService.getUsuarios(null).subscribe((data: Usuario[]) => {
        this.Usuarios = data;
      });

      this.ventasProductoService.getVentasProductos(this.id,null).subscribe((data: Ventaproducto[]) => {
        this.ventasProductos = data;
      });
    });
  }

 onSubmit(){
    this.usuariosService.getUsuarios(this.EditVentasForm.value.usuario).subscribe((data: Usuario[]) => {
      let usuario = data[0];
      this.EditVentasForm.value.nombre_usuario = usuario.nombre;
      this.ventasService.updateVenta(this.EditVentasForm.value,this.id).subscribe((res) => {
        this.router.navigate(['/listarVentas'])
      });
    });
  }

  deleteRow = (ventaProducto) => {
    this.productoService.getProductos(ventaProducto.idProducto).subscribe((data: Producto[]) => {
      let productoSeleccionado = data[0];

      var cant =  productoSeleccionado.cantidad.toString();
      var cantFinal;
      var tempValor = ventaProducto.cantidad.toString();
      //devuelve al valor original
      cantFinal = parseInt(cant) + parseInt(tempValor);
      productoSeleccionado.cantidad = cantFinal;
      this.productoService.updateProducto(productoSeleccionado,productoSeleccionado.id).subscribe((res) => {


        this.ventasService.getVentas(ventaProducto.idVenta).subscribe((data: Venta[]) => {
          let ventaSeleccionada = data[0];
    
          var valor =  ventaSeleccionada.valor.toString();
          var valorFinal;
          var tempo = ventaProducto.valor.toString();
          //devuelve al valor original
          valorFinal = parseInt(valor) - parseInt(tempo);
          ventaSeleccionada.valor = valorFinal;

          this.EditVentasForm = this.formBuilder.group({
            nombre: [this.ventasRef.nombre],
            descripcion: [this.ventasRef.descripcion],
            valor: [valorFinal],
          })

          this.ventasService.updateVenta(ventaSeleccionada,ventaSeleccionada.id).subscribe((res) => {
              this.ventasProductoService.deleteVentaProductoLogic(ventaProducto.id).subscribe((res) => {
                this.ventasProductoService.getVentasProductos(null,null).subscribe((data: Ventaproducto[]) => {
                  this.ventasProductos = data;
                });
              });
            });
        });
        });
    });
  }
  
  fileChange(event) {
    const fileList: FileList = event.target.files;
    //check whether file is selected or not
    if (fileList.length > 0) {

        const file = fileList[0];
        //get file information such as name, size and type
        console.log('finfo',file.name,file.size,file.type);
        //max file size is 4 mb
        if((file.size/1048576)<=4)
        {
          let formData = new FormData();
          let info={id:2,name:'raja'}
          formData.append('file', file, file.name);
          formData.append('id','2');
          formData.append('tz',new Date().toISOString())
          formData.append('update','2')
          formData.append('info',JSON.stringify(info))
          this.file_data=formData
          this.imageService.uploadImage(this.file_data).subscribe( res => {
            console.log(res);
            this.EditVentasForm.value.imagen = res;
          }, (err) => {
            console.log(err);
          });
        }else{
          //this.snackBar.open('File size exceeds 4 MB. Please choose less than 4 MB','',{duration: 2000});
        }
    }
  }
}
