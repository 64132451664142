import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import { ProductosComponent } from './pages/productos/listarProductos/productos.component';
//firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { EditarComponent } from './pages/usuarios/editar/editar.component';
import { AgregarComponent } from './pages/usuarios/agregar/agregar.component';

import { environment } from 'environments/environment';
import { ListadoComponent } from './pages/usuarios/listado/listado.component';
import { AgregarProductosComponent } from './pages/productos/agregar-productos/agregar-productos.component';
import { EditarproductosComponent } from './pages/productos/editarproductos/editarproductos.component';
import { ListarcomprasComponent } from './pages/compras/listarcompras/listarcompras.component';
import { AgregarcomprasComponent } from '@pages/compras/agregarcompras/agregarcompras.component';
import { EditarcomprasComponent } from '@pages/compras/editarcompras/editarcompras.component';
import { AgregarventasComponent } from '@pages/ventas/agregarventas/agregarventas.component';
import { EditarventasComponent } from '@pages/ventas/editarventas/editarventas.component';
import { ListarventasComponent } from '@pages/ventas/listarventas/listarventas.component';
import { AgregarmaterialComponent } from './pages/material/agregarmaterial/agregarmaterial.component';
import { ListarmaterialComponent } from './pages/material/listarmaterial/listarmaterial.component';
import { EditarmaterialComponent } from './pages/material/editarmaterial/editarmaterial.component';
import { ListartareasComponent } from './pages/tareas/listartareas/listartareas.component';
import { AgregartareasComponent } from './pages/tareas/agregartareas/agregartareas.component';
import { EditartareasComponent } from './pages/tareas/editartareas/editartareas.component';
import { ListardeudasComponent } from './pages/deudas/listardeudas/listardeudas.component';
import { AgregardeudasComponent } from './pages/deudas/agregardeudas/agregardeudas.component';
import { EditardeudasComponent } from './pages/deudas/editardeudas/editardeudas.component';
import { EditarpagosComponent } from './pages/pagos/editarpagos/editarpagos.component';
import { AgregarpagosComponent } from './pages/pagos/agregarpagos/agregarpagos.component';
import { EditarventasproductosComponent } from './pages/ventas/editarventasproductos/editarventasproductos.component';
import { AgregarventasproductosComponent } from './pages/ventas/agregarventasproductos/agregarventasproductos.component';
import { AgregarcomprasmaterialesComponent } from './pages/compras/agregarcomprasmateriales/agregarcomprasmateriales.component';
import { EditarcomprasmaterialesComponent } from './pages/compras/editarcomprasmateriales/editarcomprasmateriales.component';
import { AgregarotrosComponent } from '@pages/material/agregarotros/agregarotros.component';
import { ListarotrosComponent } from '@pages/material/listarotros/listarotros.component';
import { EditarotrosComponent } from './pages/material/editarotros/editarotros.component';
import { AgregarcomprasotrosComponent } from './pages/compras/agregarcomprasotros/agregarcomprasotros.component';

import { Chart } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { ModalimagenComponent } from './pages/modals/modalimagen/modalimagen.component';
import { ModalimagencompraComponent } from './pages/modals/modalimagencompra/modalimagencompra.component';
import { AgregarabonoComponent } from './pages/abono/agregarabono/agregarabono.component';
import { ListarabonosComponent } from './pages/abono/listarabonos/listarabonos.component';


defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        ProductosComponent,
        EditarComponent,
        AgregarComponent,
        ListadoComponent,
        AgregarProductosComponent,
        EditarproductosComponent,
        ListarcomprasComponent,
        AgregarcomprasComponent,
        EditarcomprasComponent,
        AgregarventasComponent,
        EditarventasComponent,
        ListarventasComponent,
        AgregarmaterialComponent,
        ListarmaterialComponent,
        EditarmaterialComponent,
        ListartareasComponent,
        AgregartareasComponent,
        EditartareasComponent,
        ListardeudasComponent,
        AgregardeudasComponent,
        EditardeudasComponent,
        EditarpagosComponent,
        AgregarpagosComponent,
        EditarventasproductosComponent,
        AgregarventasproductosComponent,
        AgregarcomprasmaterialesComponent,
        EditarcomprasmaterialesComponent,
        ListarotrosComponent,
        AgregarotrosComponent,
        EditarotrosComponent,
        AgregarcomprasotrosComponent,
        ModalimagenComponent,
        ModalimagencompraComponent,
        AgregarabonoComponent,
        ListarabonosComponent
    ],
    imports: [
        BrowserModule,
        NgChartsModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        BrowserAnimationsModule, 
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        FormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
