<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Editar Gasto</h1>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="content">
    <div class="container-fluid">
        <form action="" [formGroup]="EditComprasForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="mb-3">
                <label class="form-label">Nombre:</label>
                <input type="text" formControlName="nombre" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Descripcion:</label>
                <input type="text" formControlName="descripcion" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Valor:</label>
                <input type="text" formControlName="valor" type="text" class="form-control" [readonly]="true">
            </div>
            <div class="mb-3">
                <label class="form-label">Empresa:</label>
                <input type="text" formControlName="empresa" type="text" class="form-control" required>
            </div>
            <div class="mb-3">
                <label class="form-label">Usuario:</label>
                <p>
                    <select class="custom-select" formControlName="usuario" name="miselect" required>
                        <option>Seleecione Usuario</option>
                        <option [value]="usuario.id" *ngFor="let usuario of Usuarios">{{usuario.nombre}}</option>
                    </select>
                </p>
            </div>
            <div class="mb-3">
                <label class="form-label">Medio Pago:</label>
                <p>
                    <select class="custom-select" formControlName="medio_pago" name="miselect" required>
                        <option>Seleecione Medio de Pago</option>
                        <option value="Transferencia">Transferencia</option>
                        <option value="Efectivo">Efectivo</option>
                        <option value="Debito">Debito</option>
                        <option value="Credito">Credito</option>
                        <option value="Deuda">Deuda</option>
                    </select>
                </p>
            </div>

            <div class="mb-3">
                <label class="form-label">Evidencia:</label>
                <div class="input-group">
                    <div class="custom-file">
                        <input id="file"
                        (change)="fileChange($event)" 
                        type="file" placeholder="File">
                        <label class="custom-file-label" for="file">Seleccione Archivo</label>
                    </div>

                    <div *ngIf="comprasRef.imagen !='' " class="input-group-append">
                        <a class="input-group-text" routerLink="/imagenCompra/{{comprasRef.id}}">Ver Imagen</a>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label">Fecha de Gasto:</label>
                <input #myInput formControlName="fecha_edicion" type="text" class="form-control" required/>
            </div>

            <button [disabled]="!EditComprasForm.valid" type="submit" class="btn btn-success">Guardar</button>
        </form>

        <br>
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>Insumos y Otros del Gasto</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <button routerLink="/agregarCompraOtro/{{id}}" class="btn btn-success" type="button">
                                    <i class="fas fa-plus-circle"></i> Agregar Otro al Gasto
                                </button>
                            </li>
                            <li class="breadcrumb-item">
                                <button routerLink="/agregarCompraMaterial/{{id}}" class="btn btn-success" type="button">
                                    <i class="fas fa-plus-circle"></i> Agregar Insumo al Gasto
                                </button>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Fecha Creación</th>
                    <th scope="col">Fecha Edición</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <tr *ngFor="let compraMaterial of comprasMateriales">
                    <td scope="row">  </td>
                    <td> {{compraMaterial.nombre}} </td>
                    <td> {{compraMaterial.cantidad}} </td>
                    <td> {{compraMaterial.valor}} </td>
                    <td> {{compraMaterial.fecha_creacion}} </td>
                    <td> {{compraMaterial.fecha_edicion}} </td>
                    <td>
                        <button routerLink="/editarCompraMaterial/{{compraMaterial.id}}" class="btn btn-outline-secondary"> <i class="fas fa-pen"></i></button>
                        <button (click)="deleteRow(compraMaterial)"class="btn btn-outline-danger"> <i class="fas fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>