
export const environment = {
    production: false,
    firebaseConfig : {
        apiKey: "AIzaSyApu-IWJQxL-0QhQfcTb4ZRZjxmAmLOPk8",
        authDomain: "coquena-d7196.firebaseapp.com",
        projectId: "coquena-d7196",
        storageBucket: "coquena-d7196.appspot.com",
        messagingSenderId: "639283194433",
        appId: "1:639283194433:web:1b1516ea88487922fff4c0"
      }
};

