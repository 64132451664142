import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Compramaterial } from '@models/compramaterial.model';

@Injectable({
  providedIn: 'root'
})
export class CompramaterialService {

  constructor(public http: HttpClient) { }

  getComprasMateriales(idCompra,id){
    const data = {
      id: id,
      idCompra:idCompra
    };
    return this.http.post<Compramaterial[]>('https://inclunova.cl/sgiCoquena/compraMaterialGet.php',data);
  }

  getComprasMaterialesEstadistica(idCompra,id){
    const data = {
      id: id,
      idCompra:idCompra
    };
    return this.http.post<Compramaterial[]>('https://inclunova.cl/sgiCoquena/compraMaterialGetEstadistica.php',data);
  }


  createCompraMaterial(compraMaterial:Compramaterial){
    let link= 'https://inclunova.cl/sgiCoquena/compraMaterialInsert.php';
    let data = {
      idCompra:compraMaterial.idCompra,
      idMaterial:compraMaterial.idMaterial,
      nombre:compraMaterial.nombre,
      cantidad: compraMaterial.cantidad,
      valor: compraMaterial.valor
    };
    return this.http.post(link, data);
  }

  updateCompraMaterial(compraMaterial:Compramaterial,id){
    let link= 'https://inclunova.cl/sgiCoquena/compraMaterialUpdate.php';
    let data = {
      id:id,
      cantidad: compraMaterial.cantidad,
      valor: compraMaterial.valor
    };
    return this.http.post(link, data);
  }

  deleteCompraMaterialLogic(id){
    let link= 'https://inclunova.cl/sgiCoquena/compraMaterialDelete.php';
    let data = {
        id:id
    };
    return this.http.post(link, data)
  }
}
